import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LandingPageDataResponse } from 'src/app/models/landing-page-data-response.model';
import { Offer } from 'src/app/models/offer.model';

@Component({
  selector: 'app-dialog-warranty-alert',
  templateUrl: './dialog-warranty-alert.component.html',
  styleUrls: ['./dialog-warranty-alert.component.scss']
})
export class DialogWarrantyAlertComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogWarrantyAlertComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { offer: Offer, partner: LandingPageDataResponse },
  ) {
  }

  ngOnInit() {
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }

  public cancelCreditProposal(): void {
    this.dialogRef.close(true);
  }

}
