import {Component, Injector, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {helpTabData} from 'src/app/data/help-tab.data';
import {HelpTopic, QuestionAnswer, Topics} from 'src/app/models/help-tab.model';
import {UtilComponent} from "../../util/util.component";

@Component({
  selector: 'app-help-tab',
  templateUrl: './help-tab.component.html',
  styleUrls: ['./help-tab.component.scss']
})
export class HelpTabComponent extends UtilComponent implements OnInit {

  helpTabData!: HelpTopic[];

  currentTopic!: Topics;

  currentTopicData!: HelpTopic;

  filteredTabData!: HelpTopic[];

  filter!: FormControl;

  supportEmailAddress =
    'contact-project+blue-tech-crediblue-web-admin-crediblue@incoming.gitlab.com';
  supportEmailSubject =
    'Segue%20minha%20sugest%C3%A3o%20de%20melhoria%20ou%20corre%C3%A7%C3%A3o.';
  supportEmailBody =
    '1%20-%20Solicita%C3%A7%C3%B5es%20de%20melhorias%20e%20adicionais' +
    '%20%C3%A0%20solu%C3%A7%C3%A3o%3A%0A%20%20%20%0A%20%20%20%201.1%20-%20Descreva%20aqui' +
    '%20um%20resumo%20do%20que%20motiva%20a%20melhoria%3A%0A%0A%20%0A%0A%20%20%20%201.2%20' +
    '-%20Descreva%20aqui%2C%20como%20sugere%20que%20devamos%20melhorar%20nossa%20solu%C3%A7%' +
    'C3%A3o%3A%0A%0A%20%0A%0A%20%0A%0A2%20-%20Em%20caso%20de%20problemas%20ou%20incosist%C3%A' +
    'Ancias%20na%20solu%C3%A7%C3%A3o%3A%0A%0A%20%0A%0A%20%20%20%202.1%20-%20Fluxo%20executado' +
    '%20para%20reprodu%C3%A7%C3%A3o%2C%20em%20caso%20de%20erros%3A%0A%20%20%20%20%20%20%20%201%' +
    '20-%20%0A%20%20%20%20%20%20%20%202%20-%20%0A%20%20%20%20%20%20%20%203%20-%0A%20%20%20%20%20' +
    '%20%20%204%20-%0A%0A%20%0A%0A%20%20%20%202.2%20-%20Funcionalidades%20afetadas%3A%0A%0A%20%0A' +
    '%0A3%20-%20Anexe%20imagens%2C%20v%C3%ADdeos%2C%20documentos%20para%20ilustrar%20ou%20exemplif' +
    'icar%20a%20solicita%C3%A7%C3%A3o%3A%0A%0A';

  constructor(injector: Injector) {
    super(injector);
  }

  get filterValue(): string {
    return this.filter.value;
  }

  ngOnInit(): void {
    this.setPageTitleAndDescription();
    this.filteredTabData = JSON.parse(JSON.stringify(helpTabData));
    this.currentTopicData = this.filteredTabData[0];
    this.currentTopic = 'for-partners';
    this.createFilterControl();
  }

  changeCurrentData(type: Topics) {
    this.currentTopic = type;
    this.currentTopicData = this.filteredTabData.filter(
      val => val.topic == type
    )[0];
  }

  createFilterControl(): void {
    this.filter = new FormControl('');
  }

  onChangeValue(): void {
    this.filterData();
  }

  filterData(): void {
    this.filteredTabData = JSON.parse(JSON.stringify(helpTabData));
    for (let topic of this.filteredTabData)
      this.filterTopicData(topic);
    this.changeCurrentData(this.currentTopic);
  }

  filterTopicData(topic: HelpTopic): void {
    const questions: QuestionAnswer[] = topic.questions;
    const filtered = questions.filter(val => val.question.toLowerCase().match(this.filterValue.toLowerCase()) || val.answer.toLowerCase().match(this.filterValue.toLowerCase()));
    topic.questions = filtered;
  }

  setPageTitleAndDescription() {
    this.customizeMetatags(
      'Ajuda | Crediblue',
      'Perguntas frequentes sobre parceiros, crédito e clientes.'
    );
  }
}
