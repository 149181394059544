import { Component } from '@angular/core';
import { BlockUIService } from 'ng-block-ui';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private blockUIService: BlockUIService) {}

  public get isBlockUIActive(): boolean {
    return this.blockUIService.isActive();
  }
}
