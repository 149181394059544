import {Component, Injector, OnInit} from '@angular/core';
import {bePartnerAdvantages, businessBenefits, partnerTechAdvantages} from 'src/app/data/partner-advantages.data';
import {TextImage, TitleAndSubtitle} from 'src/app/models/contents.model';
import {PartnerAdvantage, PartnerAdvantageType} from 'src/app/models/partner-advantages.model';
import {UtilComponent} from "../../util/util.component";

@Component({
  selector: 'app-for-partners',
  templateUrl: './for-partners.component.html',
  styleUrls: ['./for-partners.component.scss']
})
export class ForPartnersComponent extends UtilComponent implements OnInit {

  advantages!: TextImage[];

  partnerTechAdvantages!: PartnerAdvantage[];

  benefits!: TitleAndSubtitle[];

  currentType!: 'tech' | 'platform' | 'lp' | 'onboarding';

  currentTypeData!: PartnerAdvantage;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.setPageTitleAndDescription();
    this.advantages = bePartnerAdvantages;
    this.partnerTechAdvantages = partnerTechAdvantages;
    this.benefits = businessBenefits;
    this.currentType = 'tech';
    this.currentTypeData = this.partnerTechAdvantages[0];
  }

  changeCurrentData(type: PartnerAdvantageType): void {
    this.currentType = type;
    this.currentTypeData = this.partnerTechAdvantages.filter(
      val => val.type == type
    )[0];
  }

  setPageTitleAndDescription() {
    this.customizeMetatags(
      'Para Parceiros | Crediblue',
      'Geramos soluções personalizadas que cabem na realidade dos nossos parceiros.'
    );
  }
}
