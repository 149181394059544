import { MainContent } from "../models/contents.model";

export const crediblueValues: MainContent[] = [
    {
        title: 'Dinamismo',
        text: 'A capacidade de adaptação é uma das nossas maiores forças. Somos dinâmicos porque enxergamos caminhos.',
        image: 'dynamic'
    },
    {
        title: 'Flexibilidade',
        text: 'Transformamos os modelos convencionais ao propor formas diferentes de ofertar novos negócios. Nosso time adapta solução à demanda.',
        image: 'flexible'
    },
    {
        title: 'Propositiva',
        text: 'Inovamos nos serviços que oferecemos. Temos um jeito mais leve e ativo enquanto empresa e relacionamento. Isso é o que nos faz buscar com dedicação pelos melhores resultados.',
        image: 'propositional'
    },
    {
        title: 'Humano',
        text: 'Respeitamos nossos parceiros e clientes para construirmos relações transparentes e duradouras. Valorizamos o diálogo, trajetórias, pessoas.',
        image: 'human'
    },
]