<div class="post-card-container">
    <div class="post-card" (click)="openPostVisualization()">
        <img [src]="image$ | async" [alt]="title$ | async" />
        <div class="post-card__details">
            <span>
                <p>{{ publishedAt$ | async }}</p>
                <p>{{ category$ | async }}</p>
            </span>
            <h5>{{ title$ | async }}</h5>
        </div>
    </div>
</div>