<section class="height">
  <main class="p-relative bg-image flex-item">
    <div class="credi-container z-index-1 flex-responsive-container">
      <h1 class="purple-400">Olá, somos <br> a Crediblue</h1>
      <h5 class="blue-1000"><br>
        Somos uma <i>fintech</i> especialista em soluções<br>
        digitais para a oferta de crédito.<br><br>
        A nossa rede de parceiros atua em todo o<br>
        Brasil. Juntos, acreditamos no crédito para<br>
        impulsionar projetos e negócios.
      </h5>
      <a class="flat blue-600 m-top-64 m-bottom-84 max-width animated" routerLink="/be-partner">
        QUERO SER PARCEIRO CREDIBLUE!
      </a>
    </div>
    <app-cred-icon name="crediblue-bg" size="n7" class="pos-absolute pos-top-right credi-simbol-right"></app-cred-icon>
  </main>
</section>

<section class="credi-container credi-center-section f-row-reverse f-gap-32 f-align-top height">
  <div class="padding-top">
    <div class="flex-item f-60 f-column f-gap-32 f-align-right m-top-32 sm-f-center">
      <h2 class="purple-400 align-right text-align-center">Simples e ágil como toda inovação deve ser.</h2>
      <h5 class="blue-1000 text-align-center">
        Captação, gestão e operação de crédito em um <strong> mesmo</strong> ambiente.<br>
        Proporcione taxas flexíveis, análises ágeis e avaliações de imóveis
        aos seus clientes em todo o Brasil.
      </h5>
      <a class="flat blue-600 max-width animated m-top-32" routerLink="/be-partner">VEM SER PARCEIRO CREDIBLUE!</a>
    </div>
    <div class="flex-item f-40 left-img bg-image-style"></div>
  </div>
</section>
<section class="blue-700 flex-item responsive-blue-section">
  <div class="flex-item f-center-section f-50 flex-responsive-container-left">
    <h3 class="white font-w-400">
      <strong>Soluções</strong> que se adaptam.<br>
      <strong>Oportunidades</strong> que impulsionam.<br>
      <strong>Parceria</strong> que vai além.<br>
    </h3>
  </div>
  <div class="f-50 oncall bg-image-home"></div>
</section>

<section class="credi-container credi-center f-row-reverse f-gap-32 f-align-top p-vertical-24 height">
  <div class="flex-item f-center ms-f-50-p f-50 f-column f-gap-32 f-align-right m-top-32 h-600">
    <h2 class="blue-700 align-right text-align-center">Uma fintech para chamar de sua!</h2>
    <h5 class="blue-1000 text-align-center">
      Parceria e tecnologia para facilitar as suas operações
      de crédito: com a Crediblue, você tem consultoria
      especializada a todo o momento.
    </h5>
  </div>
  <div class="flex-item f-50 ms-f-50 old-img bg-image-home"></div>
</section>

<section class="m-bottom-120 pos-relative margin-bottom credi-container">
  <div class="flex-item gs-flex-column flex-responsive-container">
    <div class="gs-w-50 m-bottom-84 z-index-1 align-itens-cards">
      <h2 class="purple-400 default-text">
        Parceria perfeita<br> para o seu negócio
      </h2>
      <h5 class="blue-1000"><br>
        Facilitamos as operações para otimizar o seu tempo.
        Assim, você consegue focar em novos negócios e no
        relacionamento com seus clientes. As soluções da
        Crediblue são perfeitas para todo tipo de parceiro.
      </h5>
      <a class="flat blue-600 m-top-64 m-bottom-84 max-width animated" routerLink="/be-partner">
        SEJA UM PARCEIRO AGORA MESMO!
      </a>
    </div>
    <div class="gs-w-50 overflow-hidden align-itens-cards">
      <app-home-slider class="align-itens-cards_style"></app-home-slider>
    </div>
  </div>
  <app-cred-icon name="crediblue-bg-left" size="n7"
                 class="pos-absolute pos-bottom-left z-index-0 credi-simbol-left">
  </app-cred-icon>
</section>

<section class="grey-600-o p-top-40">
  <div class="credi-container flex-item f-column f-gap-48">
    <h2 class="blue-600">
      O que os parceiros dizem
    </h2>
    <app-testimony-slider></app-testimony-slider>
  </div>
</section>

<section class="credi-container credi-center m-top-36 p-vertical-64-horizontal-0">
  <app-newsletter-form></app-newsletter-form>
</section>

<section class="credi-container f-column f-gap-48 m-top-36 m-bottom-84">
  <h3 class="purple-400 m-bottom-84">O que estão falando da Crediblue na mídia</h3>
  <div class="flex-item f-center f-gap-48">
    <ng-container *ngFor="let report of reports.slice(0, numeroReportagensExibidas)">
      <app-report-card [report]="report"></app-report-card>
    </ng-container>
  </div>
  <ng-container>
    <div class="text-center">
      <button class="btn btn-primary hide-me" type="button" (click)="toggleCollapse()"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseReports">
        Ver mais mídia
      </button>
      <div id="collapseReports" [collapse]="isCollapsed" [isAnimated]="true">
        <div class="report flex-item f-center f-gap-48">
          <ng-container *ngFor="let report of reports.slice(numeroReportagensExibidas, reports.length)">
            <app-report-card [report]="report"></app-report-card>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</section>
