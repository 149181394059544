import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { Offer } from '../models/offer.model';
import { Term } from '../models/term.model';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  urlBase: string;
  constructor(private http: HttpClient) {
    this.urlBase = `${environment.apiUrl}/offer`
  }

  public findProposalOfferById(offerId: string | number): Observable<Offer> {
    return this.http.get<Offer>(`${this.urlBase}/${offerId}`);
  }

  public confirmOfferTermsAgree(
    offerId: string | number,
    partnerId: number | any,
    terms: Term[],
    clientOriginIp: string,
    clientOriginFingerprint: string,
    dataSnapshotForTermRendering: string
  ): Observable<Offer> {
    return this.http.post<Offer>(`${this.urlBase}/confirmation`, {
      offerId,
      partnerId,
      terms,
      clientOriginIp,
      clientOriginFingerprint,
      dataSnapshotForTermRendering
    });
  }

  public cancelOffer(offerId: number): Observable<void> {
    return this.http.get<void>(`${this.urlBase}/${offerId}/cancel`);
  }
}
