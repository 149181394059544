import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {

  private readonly COMMON_MAX_CHARS: number = 50;

  private readonly ELLIPSIS: string = '...';

  public transform(value: string, ...args: unknown[]): string {
    if(value?.length < this.COMMON_MAX_CHARS)
      return value;
    const size: number = args[0] as number || this.COMMON_MAX_CHARS;
    const words: string[] = [];
    value?.split(' ')?.forEach(
      (word: string) => {
        if(words.join(' ').length + word.length + this.ELLIPSIS.length < size)
          words.push(word);
      }
    );
    return words?.join(' ')?.concat(this.ELLIPSIS);
  }

}
