import {
  Directive,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[expandable]',
  host: {
    class: 'expandable',
  },
})
export class ExpandableDirective {
  @Output()
  public expandedChange: EventEmitter<boolean> = new EventEmitter();
  private expandedValue: boolean;
  private element: HTMLElement;
  private header: HTMLElement;
  constructor(ref: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.element = ref.nativeElement;
  }

  @Input()
  public set expandable(header: HTMLElement) {
    this.header = header;
    if (this.header) {
      this.renderer.listen(this.header, 'click', () => {
        this.expandedValue = !this.expandedValue;
        this.onChange(this.expandedValue);
      });
    }
  }
  @Input()
  public set expanded(open: boolean) {
    this.expandedValue = open;
    this.onChange(open);
  }

  private onChange(expanded: boolean) {
    this.expandedChange.emit(expanded);
    this.renderer.setAttribute(
      this.element,
      'expanded-area',
      String(expanded).toLowerCase()
    );

    if (this.header) {
      this.renderer.setAttribute(
        this.header,
        'expanded-area',
        String(expanded).toLowerCase()
      );
    }
  }
}
