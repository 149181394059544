import { TextImage, TitleAndSubtitle } from "../models/contents.model";
import { PartnerAdvantage } from "../models/partner-advantages.model";

export const bePartnerAdvantages: TextImage[] = [
    {
        text: 'Você escolhe quanto ganha por operação de crédito',
        image: 'pile'
    },
    {
        text: 'Alta taxa de conversão',
        image: 'graph'
    },
    {
        text: 'Gerente de contas sempre à disposição',
        image: 'trade'
    },
    {
        text: 'Plataforma gratuita de gerenciamento de operação. ',
        image: 'options'
    },
    {
        text: 'Fidelize seus clientes com soluções financeiras',
        image: 'choices'
    },
];

export const partnerTechAdvantages: PartnerAdvantage[] = [
    {
        type: 'tech',
        title: 'Tecnologia',
        image: 'technology',
        advantages: [
            'Inteligência Artificial na estruturação da operação.',
            'Automação no comitê digital, crédito, garantia e liquidação.',
            'Integração com instituições financeiras no processo de liquidação das operações.',
            'API (Interface de Programação de Aplicação).'
        ]
    },
    {
        type: 'platform',
        title: 'Plataforma Crediblue',
        image: 'platform',
        advantages: [
            'Totalmente intuitivo. Transparência que o seu negócio precisa.',
            'Ambiente 100% gratuito para acompanhar as operações.',
            'Perfil para seus representantes e analistas.',
            'Acompanhamento em tempo real dos status das operações de crédito.',
            'Gerenciamento de suas operações.',
            'Segurança: armazenamento da documentação em nuvem.'
        ]
    },
    {
        type: 'lp',
        title: 'Landing Page',
        image: 'notebook',
        advantages: [
            'Landing Page personalizada com a identidade visual da sua marca.',
            'Geração e acompanhamento de leads.',
            'Integração com ferramentas de marketing.',
            'Domínio próprio (é seu!).',
            'Simulador de operações de crédito.'
        ]
    },
    {
        type: 'onboarding',
        title: 'Portal do Parceiro',
        image: 'onboarding',
        advantages: [
            'Materiais para prospecção de novos negócios.',
            'Templates para redes sociais.',
            'Materiais de marketing para imprimir e te auxiliar.',
            'Ambiente de capacitação para se tornar expert no mercado.',
            'Materiais de suporte de operações para te ajudar no dia a dia do seu negócio.'
        ]
    },
];

export const businessBenefits: TitleAndSubtitle[] = [
    {
        title: 'Simples & Ágil',
        subtitle: 'Como toda inovação deve ser'
    },
    {
        title: 'Confiança & Transparência',
        subtitle: 'Para viabilizar melhores oportunidades'
    },
    {
        title: 'Conexão & Comprometimento',
        subtitle: 'Porque nossa parceria vai muito além de negócios'
    },
]
