<div class="container">
  <a href=""> </a>
  <h4>POLÍTICA DE PRIVACIDADE E TRATAMENTO DE DADOS DA CREDIBLUE (“POLÍTICA”)</h4>
  <div>
    <h6>1. INTRODUÇÃO</h6>
    <div class="margin-vertical">
      A presente Política tem por finalidade demonstrar o compromisso da
      <div class="blue-box">
        <p class="box-title"><strong>Crediblue Soluções Financeiras e Imobiliárias LTDA</strong> (“Nós”),</p>
        Av. Dep. Jamel Cecílio, 2496, Ed. New Business Style 12º andar, Jardim Goiás, Goiânia, Goiás,
        CEP:
        74810-100, devidamente inscrita no CNPJ 36.750.257/0001-08
      </div>
      com a privacidade e proteção dos seus Dados, além de estabelecer as regras sobre o Tratamento dos seus Dados
      Pessoais, dentro do escopo dos serviços e funcionalidades do Aplicativo (“Nosso Aplicativo”), de acordo com
      as
      leis em vigor, com transparência e clareza junto a Você e ao mercado em geral.
    </div>
    <p>
      Como condição para acesso e uso das funcionalidades exclusivas no Nosso Aplicativo, <u>Você declara que fez
      a leitura completa e atenta desta Política, estando plenamente ciente, conferindo, assim, sua livre e
      expressa concordância com os termos aqui estipulados, incluindo a coleta dos Dados aqui mencionados, bem
      como sua
      utilização para os fins abaixo especificados</u>. Caso Você não esteja de acordo com as disposições
      desta Política, Você deverá descontinuar o seu acesso ou uso do Nosso Aplicativo.
    </p>
    <div class="margin-vertical blue-box">
      <p class="box-title center-text"><strong>NOTA ESPECIAL PARA CRIANÇAS E ADOLESCENTES COM IDADE INFERIOR A 18
        ANOS</strong></p>
      Por favor, não se registre em Nosso Aplicativo caso Você tenha menos de 18 anos.
      <p class="box-title__margin-top center-text"><strong>NOTA ESPECIAL PARA OS REPRESENTANTES LEGAIS</strong>
      </p>
      Apesar de proibirmos o registro de crianças com idade inferior a 18 anos, os pais e representantes legais
      devem supervisionar as atividades online de quaisquer crianças e adolescentes sob sua responsabilidade.
    </div>
  </div>

  <div>
    <h6>2. SOBRE DADOS QUE COLETAMOS</h6>

    <p>
      <strong>2.1. Como coletamos Dados.</strong> Os Dados, incluindo Dados Pessoais, poderão ser coletados quando
      Você os
      submete ou
      quando Você interage com Nosso Aplicativo e serviços, o que inclui:
    </p>

    <table>
      <caption>Blue Tecnologia Tabela Como Coletamos Dados 2021</caption>
      <tbody>
      <tr class="table-title-color">
        <th id="colected-data">
          <p class="text-white"><strong>Dados coletados</strong></p>
        </th>
        <th id="purpose">
          <p class="text-white"><strong>Finalidade</strong></p>
        </th>
      </tr>
      <tr class="tr-color">
        <td colspan="2" class="center-text">
          <p><strong>Dados cadastrais</strong></p>
        </td>
      </tr>
      <tr>
        <td style="width: 30%;">
          <p><strong>Nome completo</strong></p>
        </td>
        <td rowspan="16" class="td-purpose">
          <ul class="lower-roman">
            <li>
              <strong>Identificar e autenticar Você.</strong>
            </li>
            <li>
              <strong> Cumprir as obrigações decorrentes do uso dos nossos
                serviços.</strong>
            </li>
            <li>
              <strong> Garantir a portabilidade dos Dados cadastrais para outro Controlador do
                mesmo ramo de nossa atuação, caso solicitado por Você, cumprindo com
                obrigação do artigo 18 da Lei Geral de Proteção de Dados
                Pessoais.</strong>
            </li>
            <li>
              <strong>Ampliar nosso relacionamento, Informar Você sobre novidades,
                funcionalidades, conteúdos, notícias e demais eventos que consideramos
                relevantes para Você.</strong>
            </li>
            <li>
              <strong>Enriquecer sua experiência conosco promover nossos produtos e
                serviços.</strong>
            </li>
            <li>
              <strong> Proteger Você realizando prevenção a fraudes,
                proteção ao crédito e riscos associados, além do cumprimento
                de obrigações legais e regulatórias.</strong>
            </li>
            <li>
              <strong>Validar junto à <em>bureaux </em>e bases públicas, incluindo-se o
                Sistema de Informações de Crédito do Banco Central do Brasil
                - SCR, para verificação das suas informações e para a
                consulta de informações sobre as operações de
                crédito.</strong>
            </li>
          </ul>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>CPF</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>E-mail</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Celular</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Gênero</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Nome completo da Mãe</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Nome completo do cônjuge, se casado</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Endereços Residencial e Comercial</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Data de nascimento</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Cidade e Estado de Nascimento</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Nacionalidade</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Naturalidade</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Foto da CNH, RG ou Passaporte e os dados deste documento</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Selfie (Foto da sua própria face)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>PEP (Sim ou não, e quais vínculos caso sim)</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>US Person (Sim ou Não)</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td colspan="2">
          <p><strong>Dados de Identificação digital</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Endereço IP e Porta Lógica de Origem</strong></p>
        </td>
        <td rowspan="7" class="td-purpose">
          <ul class="lower-roman">
            <li>
              <strong>Identificar e autenticar Você.</strong>
            </li>
            <li>
              <strong> Cumprir com obrigações legais de manutenção de
                registros estabelecidas pelo Marco Civil da Internet - Lei 12.965/2014.</strong>
            </li>
            <li>
              <strong> Proteger Você realizando prevenção a fraudes,
                proteção ao crédito e riscos associados, além do cumprimento
                de obrigações legais e regulatórias.</strong>
            </li>
          </ul>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Dispositivo (versão do sistema operacional)</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Geolocalização</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Registros de data e horário cada ação que Você
            realizar</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Quais telas você acessou</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>ID da sessão</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Cookies</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td colspan="2">
          <p><strong>Dados financeiros</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Profissão</strong></p>
        </td>
        <td rowspan="3" class="td-purpose">
          <ul class="lower-roman">
            <li>
              <strong>Cumprir com obrigações legais
                para a realização de consulta à avaliação de
                crédito.</strong>
            </li>
          </ul>
        </td>
      </tr>
      <tr class="tr-color">
        <td>
          <p><strong>Renda Mensal</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Patrimônio</strong></p>
        </td>
      </tr>
      <tr class="tr-color">
        <td colspan="2">
          <p><strong>Dados biométricos</strong></p>
        </td>
      </tr>
      <tr>
        <td>
          <p><strong>Biometria facial</strong></p>
        </td>
        <td class="td-purpose">
          <ul class="lower-roman">
            <li>
              <strong>Identificar e autenticar Você.</strong>
            </li>
            <li>
              <strong>Proteger Você realizando prevenção a fraudes,
                proteção ao crédito e riscos associados, além do cumprimento
                de obrigações legais e regulatórias.</strong>
            </li>
            <li>
              <strong>Cumprir com a finalidade da Acesso Digital, que trata os Dados Pessoais de
                nome, CPF, biométricos, para aferição de score de
                autenticação biométrica facial em operações
                comerciais ou de crédito que importem risco financeiro, por meio da checagem e
                comparação dos dados informados por Você, dos dados que já
                são públicos ou dos constantes em bases de acesso público.</strong>
            </li>
          </ul>
        </td>
      </tr>
      </tbody>
    </table>
    <p>
      <strong>2.2. Dados necessários.</strong> Muitos de nossos serviços dependem diretamente de alguns Dados
      informados na
      tabela
      acima,
      principalmente Dados cadastrais. Caso você opte por não fornecer alguns desses Dados, podemos ficar
      impossibilitados
      de prestar total ou parcialmente nossos serviços à Você.
    </p>
    <p>
      <strong>2.3. DADOS DE RESPONSABILIDADE DA CREDIBLUE.</strong> A CREDIBLUE SOLUÇÕES FINANCEIRAS E
      IMOBILIÁRIAS LTDA.,
      INSCRITA NO
      CNPJ/MF SOB O
      Nº 32.402.502/0001-35, COM SEDE NA Av. Dep. Jamel Cecílio, 2496, JARDIM GOIÁS, Ed. New Business Style
      12º andar, CEP 74810-100, GOIÂNIA/GO, “CREDIBLUE” ATUARÁ JUNTO À {{partnerName | uppercase}} PARA O
      TRATAMENTO DOS SEUS DADOS PESSOAIS DE NOME, CPF, BIOMÉTRICOS, <strong><u>MEDIANTE COMPARTILHAMENTO DESTES
      DADOS
      PESSOAIS COM A CREDIBLUE</u></strong>, COM
      O OBJETIVO EXCLUSIVO DE AFERIÇÃO DE SCORE EM OPERAÇÕES COMERCIAIS OU DE CRÉDITO QUE IMPORTEM RISCO
      FINANCEIRO,
      SENDO
      RESPONSÁVEL POR ESTES DADOS PERANTE A VOCÊ, UMA VEZ QUE OS DADOS SÃO INTEGRADOS À BASE DE DADOS DA ACESSO
      DIGITAL
      PARA AS FINALIDADES AQUI INFORMADAS.
    </p>
    <p>
      <strong>2.3.1.</strong> Em relação aos Dados biométricos fornecidos, indicamos que as informações
      disponibilizadas por
      Você
      ficarão
      armazenadas na base de dados de propriedade da Crediblue sob controle desta, podendo serem utilizadas
      nos
      limites das finalidades necessárias ao cumprimento do contrato desta com a Crediblue e nos termos desta
      Política
      de
      Privacidade, podendo ainda serem utilizadas com a finalidade aqui descrita para terceiros cadastrados no
      sistema
      da
      Acesso Digital.
    </p>
    <p>
      <strong>2.3.2.</strong> Uma vez que os Dados Pessoais de nome, CPF, biométricos sejam inseridos na base da
      Acesso
      Digital,
      estes
      passarão a integrá-la por período indeterminado ou até que Você, órgão judicial ou órgão administrativo
      solicite
      a
      exclusão.
    </p>
    <p>
      <strong>2.3.3.</strong> Caso Você deseje realizar qualquer alteração ou exclusão dos seus Dados biométricos
      faciais, a
      solicitação
      deve ser direcionada diretamente à Acesso Digital, por meio de pedido efetuado através do site <a
      href="https://crediblue.com.br/" target="_blank" rel="noopener noreferrer">crediblue.com.br</a> conforme
      orientações lá
      disponíveis.
    </p>
    <p>
      <strong>2.4. Dados Biométricos.</strong> Permitimos que Você habilite a autenticação biométrica cadastrada
      em seu
      dispositivo
      móvel
      para acessar o Aplicativo. Para cumprirmos com nosso dever de transparência, informamos que não tratamos de
      dados de
      impressão digital ou de reconhecimento facial, pois estes são mantidos apenas pela Acesso Digital nos termos
      da
      cláusula 2.3. acima e pela Apple ou Google nos termos de suas respectivas Políticas de Privacidade.
    </p>
    <p>
      <strong>2.5.</strong> Atualização e Veracidade dos Dados. Você é o único responsável pela precisão,
      veracidade ou
      falta dela
      em
      relação aos Dados que você fornece ou pela sua desatualização. Fique atento pois é de sua responsabilidade
      garantir
      a exatidão ou mantê-los atualizados.
    </p>
    <p>
      <strong>2.5.1.</strong> Da mesma forma, Nós não somos obrigados a processar ou tratar quaisquer dos seus
      Dados se
      houver
      razões
      para
      crer que tal processamento ou tratamento possa nos imputar qualquer infração de qualquer lei aplicável, ou
      se
      você
      estiver utilizando Nosso Aplicativo para quaisquer fins ilegais, ilícitos ou contrários à moralidade.
    </p>
    <p>
      <strong>2.6. Base de Dados.</strong> A base de dados formada por meio da coleta de Dados é de nossa
      propriedade e está
      sob
      nossa
      responsabilidade, sendo que seu uso, acesso e compartilhamento, quando necessários, serão feitos dentro dos
      limites
      e propósitos dos negócios descritos nesta Política.
    </p>
    <p>
      <strong>2.7. Tecnologias empregadas.</strong> Nós utilizamos a(s) seguinte(s) tecnologia(s):
      (i) Cookies, cabendo a Você configurar o seu navegador de Internet caso deseje bloqueá-los. Nesta hipótese,
      algumas
      funcionalidades que oferecemos poderão ser limitadas.
      (ii) Registro de dados de navegação não identificáveis para análises do Aplicativo.
    </p>
    <p>
      <strong> 2.7.1.</strong> Todas as tecnologias utilizadas respeitarão sempre a legislação vigente e os termos
      desta
      Política.
    </p>
  </div>

  <div>
    <h6>3. COMO COMPARTILHAMOS DADOS E INFORMAÇÕES</h6>
    <p>
      <strong>3.1. Hipóteses de compartilhamento dos Dados.</strong> Os Dados coletados e as atividades
      registradas podem
      ser
      compartilhados:
    </p>
    <ul class="lower-roman">
      <li>Com autoridades judiciais, administrativas ou governamentais competentes, sempre que houver
        determinação
        legal,
        requerimento, requisição ou ordem judicial;
      </li>
      <li>
        De forma automática, em caso de movimentações societárias, como fusão, aquisição e incorporação;
      </li>
      <li>
        Com empresas do grupo econômico, fornecedores e correspondentes autorizados para atendimento das
        finalidades
        informadas nesta Política, em especial, a autenticação, ativação do cartão e acesso à consulta da fatura
        no
        Aplicativo, envio de informações sobre novidades, funcionalidades, conteúdos e demais informações
        relevantes
        para
        manutenção do nosso relacionamento com <strong>Você.</strong>
      </li>
    </ul>
    <p>
      <strong>3.2. Anonimização de Dados.</strong> Para as finalidades de pesquisas de inteligência de mercado,
      divulgação
      de dados
      à
      imprensa e realização de propagandas, os dados fornecidos por Você serão compartilhados de forma
      anonimizada,
      isto
      é, de forma que não possibilite a sua identificação.
    </p>
  </div>

  <div>
    <h6>4. COMO PROTEGEMOS SEUS DADOS E COMO VOCÊ TAMBÉM PODERÁ PROTEGÊ-LOS</h6>
    <p>
      <strong>4.1. Compartilhamento de senhas.</strong> Você também é responsável pelo sigilo de seus Dados
      Pessoais e deve
      ter
      sempre
      ciência de que o compartilhamento de senhas e dados de acesso viola esta Política e pode comprometer a
      segurança
      dos
      seus Dados e do Nosso Aplicativo.
    </p>
    <p>
      <strong>4.2. Cuidados que Você dever tomar.</strong> É muito importante que Você proteja seus Dados contra
      acessos
      não
      autorizados ao
      seu dispositivo, conta ou senha, além de se certificar de
      sempre clicar em “sair” ao encerrar sua navegação quando não for mais utilizar o seu dispositivo, ou em sua
      eventual
      troca. Também é muito importante que Você saiba que nós nunca enviaremos mensagens eletrônicas solicitando
      confirmação de dados ou com anexos que possam ser executados (extensões: .exe, .com, entre outros) ou ainda
      links
      para eventuais downloads.
    </p>
    <p>
      <strong>4.3. Acesso aos Dados Pessoais, proporcionalidade e relevância.</strong> Internamente, os Dados
      Pessoais
      coletados
      são
      acessados somente por profissionais devidamente autorizados, respeitando os princípios de proporcionalidade,
      necessidade e relevância para os objetivos do nosso negócio, além do compromisso de confidencialidade e
      preservação
      da sua privacidade nos termos desta Política.
    </p>
    <p>
      <strong>4.4. Links externos.</strong> Quando Você utilizar Nosso Aplicativo, Você poderá ser conduzido, via
      link a
      outros
      portais
      ou
      plataformas, que poderão coletar suas informações e ter sua própria Política de Tratamento de Dados.
    </p>
    <p>
      <strong>4.4.1.</strong> Caberá a você ler a Políticas de Privacidade e Tratamento de Dados de tais portais
      ou
      plataformas
      fora do
      Nosso Aplicativo, sendo de sua responsabilidade aceitá-la ou rejeitá-la. Nós não somos responsáveis pelas
      Políticas
      de Privacidade e Tratamento de Dados de terceiros e nem pelo conteúdo de quaisquer websites conteúdos ou
      serviços
      ligados à ambientes que não os nossos.
    </p>
    <p>
      <strong>4.4.2. Serviços de parceiros.</strong> Nós possuímos parceiros comerciais que, eventualmente, podem
      oferecer
      serviços
      por
      meio de funcionalidades ou sites que podem ser acessados a partir do Nosso Aplicativo. Os Dados fornecidos
      por
      Você
      a estes parceiros serão de responsabilidade destes, estando assim sujeitos às suas próprias práticas de
      coleta e
      uso
      de dados.
    </p>
    <p>
      <strong>4.5. Processamento por terceiros sob nossa diretriz.</strong> Caso empresas terceirizadas realizem o
      Tratamento
      em
      nosso
      nome
      de quaisquer Dados Pessoais que coletamos, as mesmas respeitarão as condições aqui estipuladas e as normas
      de
      segurança da informação, obrigatoriamente.
    </p>
    <p>
      <strong>4.6. Comunicação por e-mail.</strong> Para otimizar e melhorar nossa comunicação, quando enviamos um
      e-mail
      pra
      Você
      podemos
      receber uma notificação quando eles são abertos, desde que esta possibilidade esteja disponível. É
      importante
      você
      ficar atento, pois os e- mails são enviados somente pelos domínios: <a
      href="mailto:comercial@crediblue.com.br">comercial@crediblue.com.br</a> ou
      <a href="mailto:contato@crediblue.com.br">contato@crediblue.com.br</a>;
    </p>
  </div>

  <div>
    <h6>5. COMO ARMAZENAMOS SEUS DADOS PESSOAIS E O REGISTRO DE ATIVIDADES</h6>
    <div>
      <strong>5.1.</strong> Os Dados Pessoais coletados e os registros de atividades são armazenados em ambiente
      seguro e
      controlado
      por um
      prazo mínimo que segue a tabela abaixo:

      <table>
        <caption>Blue Tecnologia Tabela de Armazenamentos de Dados Pessoais e Registro de Atividades 2021</caption>
        <tbody>
        <tr class="table-title-color">
          <th style="width: 50%;" id="storage-term">
            <p class="text-white"><strong>PRAZO DE ARMAZENAMENTO</strong></p>
          </th>
          <th id="legal-foundation">
            <p class="text-white"><strong>FUNDAMENTO LEGAL</strong></p>
          </th>
        </tr>
        <tr>
          <td colspan="2" class="tr-color">
            <p><strong>Dados cadastrais</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>5 anos após o término da relação</strong></p>
          </td>
          <td>
            <p><strong>Art. 12 e 34 do Código de Defesa do Consumidor</strong></p>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="tr-color">
            <p><strong>Dados de identificação digital</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>6 meses</strong></p>
          </td>
          <td>
            <p><strong>Art. 15, Marco Civil da Internet</strong></p>
          </td>
        </tr>
        <tr>
          <td colspan="2" class="tr-color">
            <p><strong>Outros dados</strong></p>
          </td>
        </tr>
        <tr>
          <td>
            <p><strong>Enquanto durar a relação e não houver pedido de apagamento
              ou revogação de consentimento</strong></p>
          </td>
          <td>
            <p><strong>Art. 9, Inciso II da Lei Geral de Proteção de Dados
              Pessoais</strong></p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <p>
      <strong>5.2.</strong> Prazos de armazenamento superiores. Para fins de auditoria, segurança, controle de
      fraudes,
      proteção ao
      crédito
      e preservação de direitos, poderemos permanecer com o histórico de registro de seus Dados por prazo maior
      nas
      hipóteses que a lei ou norma regulatória assim estabelecer ou para preservação de direitos.
    </p>
    <div class="blue-box">
      <strong>5.3.</strong> Os Dados coletados serão armazenados em nossos servidores, bem como em
      ambiente
      de uso de recursos ou servidores na nuvem (cloud computing), <strong>o que poderá exigir uma transferência
      e/ou
      processamento destes Dados fora do Brasil.</strong>
    </div>
    <br>
  </div>

  <div>
    <h6>6. QUAIS SÃO OS SEUS DIREITOS E COMO EXERCÊ-LOS</h6>

    <p>
      <strong>6.1.</strong> Seus Direitos básicos. Você poderá solicitar ao nosso Encarregado de Dados Pessoais a
      confirmação da
      existência
      tratamento de Dados Pessoais, além da exibição ou retificação de seus Dados Pessoais, por meio do nosso
      Canal de
      Atendimento.
    </p>
    <div>
      <strong>6.2.</strong> Limitação, oposição e exclusão de dados. Pelos Canais de Atendimento, Você poderá
      também
      requerer:
      <ul class="lower-roman">
        <li>
          A limitação do uso de seus Dados Pessoais;
        </li>
        <li>
          Manifestar sua oposição e/ou revogar o consentimento quanto ao uso de seus Dados Pessoais; ou
        </li>
        <li>
          Solicitar a exclusão de seus Dados Pessoais que tenham sidos coletados por Nós.
        </li>
      </ul>
    </div>
    <p>
      <strong>6.2.1.</strong> Se Você retirar seu consentimento para finalidades fundamentais ao regular
      funcionamento do
      Nosso
      Aplicativo
      e serviços, tais ambiente e serviços poderão ficar indisponíveis para Você.
    </p>
    <p>
      <strong>6.2.2.</strong> Caso Você solicite a exclusão de seus Dados Pessoais, pode ocorrer que os Dados
      precisem ser
      mantidos por
      período superior ao pedido de exclusão, nos termos do artigo 16 da Lei Geral de Proteção de Dados Pessoais,
      para
      (i)
      cumprimento de obrigação legal ou regulatória, (ii) estudo por órgão de pesquisa, e (iii) transferência a
      terceiro
      (respeitados os requisitos de tratamento de dados dispostos na mesma Lei). Em todos os casos mediante a
      anonimização
      dos Dados Pessoais, desde que possível.
    </p>
    <p>
      <strong>6.2.3.</strong> Findos o prazo de manutenção e a necessidade legal, os Dados Pessoais serão
      excluídos com uso
      de métodos
      de
      descarte seguro, ou utilizados de forma anonimizada para fins estatísticos.
    </p>
  </div>

  <div>
    <h6>7. INFORMAÇÕES SOBRE ESSA POLÍTICA</h6>

    <p>
      <strong>7.1.</strong> Alteração do teor e atualização. Você reconhece o nosso direito de alterar o teor
      desta Política a
      qualquer
      momento, conforme a finalidade ou necessidade, tal qual para adequação e conformidade legal de disposição de
      lei
      ou
      norma que tenha força jurídica equivalente, cabendo a Você verificá-la sempre que efetuar o acesso ao Nosso
      Aplicativo ou utilizar nossos serviços.
    </p>
    <p>
      <strong>7.1.1.</strong> Ocorrendo atualizações neste documento e que demandem nova coleta de consentimento,
      Você será
      notificado
      por
      meio dos canais de contatos que Você informar.
    </p>
    <p>
      <strong>7.2.</strong> Inaplicabilidade. Caso algum ponto desta Política seja considerado inaplicável por
      Autoridade de Dados
      ou
      judicial, as demais condições permanecerão em pleno vigor e efeito.
    </p>
    <p>
      <strong>7.3.</strong> Comunicação Eletrônica. Você reconhece que toda comunicação realizada por e-mail (aos
      endereços
      informados
      no
      seu cadastro), SMS, aplicativos de comunicação instantânea ou qualquer outra forma digital, também são
      válidas,
      eficazes e suficiente para a divulgação de qualquer assunto que se refira aos serviços que prestamos, aos
      seus
      Dados, bem como às condições de sua prestação ou a qualquer outro assunto nele abordado, sendo exceção
      apenas o
      que
      essa Política prever como tal.
    </p>
    <div>
      <strong>7.4.</strong> Canais de Atendimento. Em caso de qualquer dúvida com relação às disposições
      constantes desta Política
      de
      Privacidade e Tratamento de Dados, você poderá entrar em contato por meio dos canais de atendimento
      apontados a
      seguir, cujo horário de funcionamento é de segunda à sábado, das 08h30min horas à 18h, exceto feriados
      nacionais:
      <ul class="lower-roman">
        <li>
          Fale Conosco: <a href="mailto:contato@crediblue.com.br">contato@crediblue.com.br</a>; <a
          href="mailto:comercial@crediblue.com.br">comercial@crediblue.com.br</a>
        </li>
        <li>
          Central de Atendimento: <a href="tel:6234147645">(62) 34147645</a>
        </li>
        <li>
          Redes Sociais Oficiais: Instagram: <a href="https://www.instagram.com/crediblue/"
                                                target="_blank" rel="noopener noreferrer">@crediblue</a> / Facebook: <a
          href="https://www.facebook.com/credibluebr/"
          target="_blank" rel="noopener noreferrer">credibluebr</a> / Linkedin:
          <a href="https://www.linkedin.com/company/crediblue/" target="_blank" rel="noopener noreferrer">crediblue</a>
        </li>
        <li>
          Pessoalmente ou Carta: Av. Dep. Jamel Cecílio, 2496 - Jardim Goiás, Sala 702, Ed. New Business Style
          12º andar,
          Jardim
          Goiás, Goiânia, Goiás, CEP: 74810-100
        </li>
        <li>
          Canal de Ouvidoria: <a href="mailto:ouvidoria@crediblue.com.br">ouvidoria@crediblue.com.br</a>
        </li>
      </ul>
    </div>
    <p>
      <strong>7.5. Lei aplicável e foro.</strong> Essa Política será interpretada segundo a legislação brasileira, no
      idioma
      português,
      sendo eleito o foro do seu domicílio para dirimir qualquer controvérsia que envolva este documento, salvo
      ressalva
      específica de competência pessoal, territorial ou funcional pela legislação aplicável.
    </p>
    <p>
      <strong>7.5.1.</strong> Você, caso não possua domicílio no Brasil, e em razão dos serviços oferecidos pela Empresa
      apenas em
      território nacional, se submete à legislação brasileira, concordando, portanto, que em havendo litígio a ser
      solucionado, a ação deverá ser proposta no Foro do seu domicílio .
    </p>
  </div>

  <div>
    <h6>8. GLOSSÁRIO</h6>

    <div>
      <strong>8.1.</strong> Para os fins desta Política, devem se considerar as seguintes definições e descrições para
      seu
      melhor
      entendimento:

      <ul class="lower-roman">
        <li>
          <strong>Dados:</strong> Quaisquer informações inseridas, tratadas ou transmitidas através dos Nosso
          Aplicativo.
        </li>
        <li>
          <strong> Dados Pessoais:</strong> Dados relacionados a pessoa natural identificada ou identificável.
        </li>
        <li>
          <strong> Anonimização:</strong> Utilização de meios técnicos razoáveis e disponíveis no momento do
          Tratamento,
          por meio
          dos
          quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.
        </li>
        <li>
          <strong> Dados Pessoais Sensíveis:</strong> dados pessoais sobre origem racial ou étnica, convicção
          religiosa,
          opinião
          política,
          filiação a sindicato ou a organização de caráter religioso, filosófico ou político, dados referentes
          à saúde
          ou
          à
          vida sexual, dados genéticos ou biométricos, quando vinculados a uma pessoa natural.
        </li>
        <li>
          <strong> Encarregado (Data Protection Officer - DPO):</strong> Pessoa indicada por Nós para atuar
          como canal de
          comunicação
          entre
          o controlador, os titulares dos dados e a Autoridade Nacional de Proteção de Dados (ANPD).
        </li>
        <li>
          <strong> Cloud Computing:</strong> Ou computação em nuvem, é tecnologia de virtualização de serviços
          construída a
          partir
          da
          interligação de mais de um servidor por meio de uma rede de informação comum (p.ex. a Internet), com
          objetivo de
          reduzir custos e aumentar a disponibilidade dos serviços sustentados.
        </li>
        <li>
          <strong> Nosso Aplicativo:</strong> Designa o Aplicativo para uso do cartão e acesso à fatura, além
          de outras
          funcionalidades,
          com a possibilidade de simulação de crédito.
        </li>
        <li>
          <strong> Conta de Acesso:</strong> Credencial de necessária para utilizar ou acessar as
          funcionalidades
          exclusivas do
          Nosso
          Aplicativo.
        </li>
        <li>
          <strong> Cookies:</strong> Pequenos arquivos enviados pelo Nosso Aplicativo, salvos nos seus
          dispositivos, que
          armazenam
          as
          preferências e poucas outras informações, com a finalidade de personalizar sua navegação de acordo
          com o seu
          perfil.
        </li>
        <li>
          <strong> IP:</strong> Abreviatura de Internet Protocol. É conjunto alfanumérico que identifica os
          dispositivos dos
          <strong>USUÁRIOS</strong> na
          Internet
        </li>
        <li>
          <strong>Logs:</strong> Registros de atividades de quaisquer usuários que utilizem Nosso Aplicativo.
        </li>
        <li>
          <strong> ID de Sessão:</strong> Identificação da sessão de usuários quando é efetuado o acesso ao
          Nosso
          Aplicativo.
        </li>
        <li>
          <strong>Decisões unicamente automatizadas:</strong> Tratam-se de decisões que afetam um usuário que
          foram
          programadas
          para
          funcionar automaticamente, sem a necessidade de uma operação humana, com base em tratamento
          automatizado de
          dados
          pessoais.
        </li>
        <li>
          <strong>Tratamento:</strong> Toda operação realizada com Dados Pessoais, como as que se referem a
          coleta,
          produção,
          recepção,
          classificação, utilização, acesso, reprodução, transmissão, distribuição, processamento,
          arquivamento,
          armazenamento, eliminação, avaliação ou controle da informação, modificação, comunicação,
          transferência,
          difusão
          ou
          extração.
        </li>
      </ul>
    </div>
  </div>
  <div>
    Atualização: 28 de Agosto de 2020.
  </div>
</div>
