import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './views/home/home.component';
import {CreditTypeComponent} from './views/credit-type/credit-type.component';
import {ForPartnersComponent} from './views/for-partners/for-partners.component';
import {CareersComponent} from './views/careers/careers.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ComponentsModule} from './components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbAlertModule, NgbModule, NgbPaginationModule} from '@ng-bootstrap/ng-bootstrap';
import {HelpTabComponent} from './views/help/help-tab.component';
import {BePartnerFormComponent} from "./views/be-partner-form/be-partner-form.component";
import {Interceptor} from "./http-interceptor.service";
import {NotificationService} from "./services/notification.service";
import {IConfig, NgxMaskDirective, provideEnvironmentNgxMask} from "ngx-mask";
import {defaultSimpleModalOptions, SimpleModalModule} from 'ngx-simple-modal';
import {DialogModalComponent} from "./components/modal/dialog-modal/dialog-modal.component";
import {BlogListComponent} from "./views/blog/blog-list/blog-list.component";
import {InfiniteScrollModule} from "ngx-infinite-scroll";
import {BlogPostComponent} from "./views/blog/blog-post/blog-post.component";
import { PageQualificationOfferComponent } from './views/qualification-offer/page-qualification-offer/page-qualification-offer.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogWarrantyAlertComponent } from './views/qualification-offer/dialog-warranty-alert/dialog-warranty-alert.component';
import { DialogdialogRedirectingToSimulatorComponent } from './views/qualification-offer/dialog-redirecting-to-simulator/dialog-redirecting-to-simulator.component';
import { DialogTermComponent } from './components/modal/dialog-term/dialog-term.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { CollapseModule } from "ngx-bootstrap/collapse";
import { BlockUIModule } from 'ng-block-ui';


const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    CreditTypeComponent,
    ForPartnersComponent,
    CareersComponent,
    HelpTabComponent,
    BePartnerFormComponent,
    DialogModalComponent,
    BlogListComponent,
    BlogPostComponent,
    PageQualificationOfferComponent,
    DialogWarrantyAlertComponent,
    DialogdialogRedirectingToSimulatorComponent,
    DialogTermComponent
  ],
  imports: [
    ComponentsModule,
    MatButtonToggleModule,
    MatCheckboxModule,
    MatDialogModule,
    ComponentsModule,
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    HttpClientModule,
    AppRoutingModule,
    CollapseModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgxMaskDirective,
    InfiniteScrollModule,
    MatProgressSpinnerModule,
    ClipboardModule,
    SimpleModalModule.forRoot(
      {container: 'modal-container'},
      {
        ...defaultSimpleModalOptions, ...
          {
            closeOnEscape: true,
            closeOnClickOutside: true,
            animationDuration: 300,
            autoFocus: true
          }
      }),      
    BlockUIModule.forRoot(),
  ],
  providers: [
    NotificationService,
    provideEnvironmentNgxMask(maskConfig),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }
  ],
  entryComponents: [
    DialogModalComponent
  ],
  bootstrap: [AppComponent],
  exports: [
    DialogWarrantyAlertComponent
  ]
})
export class AppModule {
}
