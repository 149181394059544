import {Component, Input} from '@angular/core';
import {TextImage} from 'src/app/models/contents.model';

@Component({
  selector: 'app-advantage-card',
  templateUrl: './advantage-card.component.html',
  styleUrls: ['./advantage-card.component.scss']
})
export class AdvantageCardComponent {

  @Input()
  advantage!: TextImage;

  @Input()
  color!: string;

  get image(): string {
    return `url('./../../../../assets/imgs/${this.advantage.image}.webp')`;
  }

  get containerClass(): string {
    const bgColor: string = this.color == 'blue' ? 'blue-100' : 'grey-600';
    return `card-container flex-item ${bgColor}`;
  }

  get textClass(): string {
    return `flex-item f-60 ${this.color}`;
  }

}
