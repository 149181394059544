import { Component, Injector, OnInit } from '@angular/core';
import { FormArray, FormGroup, Validators } from "@angular/forms";
import { provideNgxMask } from "ngx-mask";
import { BehaviorSubject, forkJoin } from 'rxjs';
import { BasicPartnerOpportunityDto } from 'src/app/models/basic-partner-opportunity-dto';
import { TermService } from 'src/app/services/term.service';
import { UtilComponent } from 'src/app/util/util.component';
import { GenericModalComponent } from "../../components/modal/generic-modal/generic-modal.component";
import { Term } from "../../models/term.model";
import { IpServiceService } from "../../services/ip-service.service";

type FormType = null | 'CNPJ' | 'CPF';

@Component({
  selector: 'app-be-partner-form',
  templateUrl: './be-partner-form.component.html',
  styleUrls: ['./be-partner-form.component.scss'],
  providers: [provideNgxMask()]
})
export class BePartnerFormComponent extends UtilComponent implements OnInit {
  private readonly PARTNER_TERM_TARGET_ENTITY = 'PARTNER';
  private readonly PARTNER_PRIVACY_POLICY_TYPE = 'PRIVACY_POLICY';

  private dataSnaphotForTermRendering: any;
  private clientOriginIp: string;
  private clientOriginFingerprint: any;
  private termToAccept: Term;

  public formType$: BehaviorSubject<FormType> = new BehaviorSubject<FormType>(null);
  public bePartnerForm: FormGroup;

  constructor(
    private ipService: IpServiceService,
    private termService: TermService,
    injector: Injector
  ) {
    super(injector);
    this.retrieveTermsData();
  }

  public get bePartnerFormValues(): BasicPartnerOpportunityDto {
    return this.bePartnerForm.value;
  }

  public get bePartnerFormControls() {
    return this.bePartnerForm.controls;
  }

  public ngOnInit() {
    this.setPageTitleAndDescription();
    this.createOpportunityForm();

    this.formType$.subscribe(selectedType => {
      if(selectedType == 'CNPJ') {
        this.handleCompanyControls()
      } else {
        this.handleNaturalPersonControls();
      }
      this.bePartnerForm.updateValueAndValidity();
    });
  }

  public onSubmit() {
    this.bePartnerForm.invalid
      ? this.notifyService.showWarning("É necessário realizar o preenchimento correto do formulário.", "Atenção")
      : this.saveBasicPartnerOpportunity(this.bePartnerFormValues);
  }

  public openTermView(term: Term): void {
    this.simpleModalService.addModal(GenericModalComponent,
      {
        termContent: term,
        termDescription: this.getTermDescriptionByType(term),
        dataSnapshotForTermRendering: this.dataSnaphotForTermRendering
      }
    );
  }

  public getTermDescriptionByType(term: Term): string {
    if (term?.type === 'PRIVACY_POLICY') {
      return 'Política de Privacidade'
    }
    else if (term?.type === 'TERMS_OF_USE') {
      return 'Termos de Uso'
    }
    else if (term?.type === 'TERM_SCR_CONSULT') {
      return 'Termo de Aceite de Consulta ao SCR'
    }
    return '';
  }

  public setFormType(formType: FormType = null): void {
    this.formType$.next(formType);
  }

  private saveBasicPartnerOpportunity(opportunity: BasicPartnerOpportunityDto): void {
    this.blockUI.start();
    this.oportunityService.saveBasicPartnerOpportunity(opportunity)
      .subscribe(
        {
          next: () => {
            this.notifyService.showSuccess('Cadastro realizado com Sucesso!', 'Feito!');
            this.blockUI.stop();
          },
          error: (error) => {
            this.notifyService.showError(error.error.message, 'Houve um erro inesperado!');
            this.blockUI.stop();
          }
      });
  }

  private retrieveTermsData(): void {
    this.blockUI.start();
    forkJoin(
      {
        clientOriginIpResponse: this.ipService.getIpAddress(),
        landingPageDataResponse: this.oportunityService.getPartnerInfo(),
        currentTenant: this.oportunityService.getCurrentTenant(),
        termToAccept: this.termService.getCurrentTermByTargetEntityAndType(
          this.PARTNER_TERM_TARGET_ENTITY, this.PARTNER_PRIVACY_POLICY_TYPE)
      }
    ).subscribe((joined) => {
      const { landingPageDataResponse, currentTenant, termToAccept, clientOriginIpResponse } = joined;

      this.dataSnaphotForTermRendering = this.buildDataSnapshotForTermRendering(landingPageDataResponse, currentTenant);
      this.clientOriginFingerprint = this.ipService.getFingerprint();
      this.clientOriginIp = clientOriginIpResponse.ip;
      this.termToAccept = termToAccept;

      this.blockUI.stop();

      this.createOpportunityForm();
    });
  }

  private createAcceptTermsFormArray(): FormArray {
    return this.fb.array([this.createAcceptTermsFormArrayItem(this.termToAccept)]);
  }

  private createAcceptTermsFormArrayItem(term: Term): FormGroup {
    return this.fb.group({
      term: [term],
      isAccepted: ['', Validators.required],
      clientOriginIp: [this.clientOriginIp],
      clientOriginFingerprint: [this.clientOriginFingerprint],
      dataSnapshotForTermRendering: [this.dataSnaphotForTermRendering]
    });
  }

  private createOpportunityForm(): void {
    this.bePartnerForm = this.fb.group({
        taxId: ['', [Validators.required, Validators.maxLength(15)]],
        name: [''],
        fantasyName: [''],
        corporateName: [''],
        email: ['', Validators.required],
        whatsapp: ['', Validators.required],
        acceptedPersonaTerms: this.createAcceptTermsFormArray()
      });
  }

  private handleCompanyControls(): void {
    this.bePartnerForm.get('name').setValidators(null);
    this.bePartnerForm.get('name').setErrors(null);
    this.bePartnerForm.get('fantasyName').setValidators([Validators.required]);
    this.bePartnerForm.get('corporateName')?.setValidators([Validators.required]);
  }

  private handleNaturalPersonControls(): void {
    this.bePartnerForm?.get('fantasyName').setValidators(null);
    this.bePartnerForm?.get('corporateName').setValidators(null);
    this.bePartnerForm?.get('fantasyName').setErrors(null);
    this.bePartnerForm?.get('corporateName').setErrors(null);
    this.bePartnerForm?.get('name')?.setValidators([Validators.required]);
  }

  private setPageTitleAndDescription(): void {
    this.customizeMetatags(
      'Seja Parceiro | Crediblue',
      'Venha impulsionar negócios. Cadastre como Parceiro Crediblue agora mesmo.'
    );
  }
}
