<main class="careers">
    <div class="credi-container credi-center f-justify-right div-style">
        <h1 class="purple-400">
            Juntos viabilizamos oportunidades para projetos, sonhos, negócios e carreiras.
        </h1>
      <a href="https://bluewaycapital.gupy.io/" target="_blank" class="flat purple-400">ACESSE AQUI NOSSA PÁGINA NA GUPY</a>
    </div>
</main>

<section class="credi-container credi-space-between flex-item section-sizing">
    <div class="flex-item f-40 f-column">
        <h2 class="purple-400">Por aqui valorizamos simplicidade, relacionamento, confiabilidade e inovação.</h2>
    </div>
    <div class="flex-item f-50 bg-image"></div>
</section>

<section class="credi-container credi-center flex-item values-section">
    <ng-container *ngFor="let value of values">
        <app-values-card [value]="value"></app-values-card>
    </ng-container>
</section>

<section class="purple-400">
    <div class="flex-item">
        <div class="flex-item f-30 section-responsive-alignment">
            <h2 class="white m-bottom-36">Como fazemos tudo isso?</h2>
            <p class="default-text white align-left">
                Trabalhamos em um modelo de gestão horizontal e,
                para termos um ambiente fluído e produtivo,
                atuamos com três pilares essenciais:
                <b>a autogestão, colaboração e comunicação</b>.
                Contamos que todos nossos colaboradores se
                sintam parte desse processo de construção e desenvolvimento.
            </p>
        </div>
        <div class="flex-item f-70 disclaimer-img bg-image"></div>
    </div>
</section>

<section class="credi-container f-column padding-bottom padding-top">
    <h2 class="purple-400">O que nosso time diz</h2>
    <div class="flex-item f-center f-gap-24 m-top-72">
        <ng-container *ngFor="let testimony of testimonies">
            <app-testimony-card [testimony]="testimony"></app-testimony-card>
        </ng-container>
    </div>
</section>

<section class="credi-container credi-center f-column f-gap-68 m-bottom-132">
    <h4 class="blue-700 font-w-600">Quer fazer parte do nosso time?<br>
        Conheça nossas oportunidades.</h4>
    <a href="https://bluewaycapital.gupy.io/" target="_blank" class="flat purple-400">ACESSE AQUI NOSSA PÁGINA NA GUPY</a>
</section>
