import { Component, ElementRef, Input, Renderer2 } from '@angular/core';
import { QuestionAnswer } from 'src/app/models/help-tab.model';

@Component({
  selector: 'crediblue-help-panel',
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss'],
  host: {
    class: 'crediblue-help-panel',
  },
})
export class HelpPanelComponent {
  @Input() public topic!: QuestionAnswer;
  private isExpanded: boolean = false;
  private host: HTMLElement;
  constructor(ref: ElementRef<HTMLElement>, private renderer: Renderer2) {
    this.host = ref.nativeElement;
  }

  public changeState(): void {
    this.isExpanded = !this.isExpanded;
    this.renderer.setAttribute(this.host, 'expanded', String(this.isExpanded));
  }
}
