import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-blue-checkbox',
  templateUrl: './blue-checkbox.component.html',
  styleUrls: ['./blue-checkbox.component.css']
})
export class BlueCheckboxComponent {

  @Input()
  control!: FormControl;

  @Input()
  text!: string;

}
