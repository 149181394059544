import {HomeCards} from "../models/contents.model";

export const homeCardsContent: HomeCards[] = [
  {
    image: 'credit-consultant',
    title: 'Consultor de crédito',
    text: 'Oportunidade perfeita para aumentar faturamento para quem está começando e para quem é expert no assunto.',
  },
  {
    image: 'business-consultant',
    title: 'Consultor empresarial',
    text: 'Oportunidade de ampliar soluções para seus clientes com estratégia na tomada de crédito.',
  },
  {
    image: 'correspondent-banking',
    title: 'Correspondente bancário',
    text: 'Oportunidade de ampliar seu portfólio de produtos',
  },
  {
    image: 'realtor',
    title: 'Corretor de imóveis',
    text: 'Oportunidade de agregar novos produtos ao seu negócio com ótimas perspectivas de crescimento',
  },
  {
    image: 'builders',
    title: 'Construtoras / Incorporadoras',
    text: 'Oportunidade para gerar novos negócios por meio da tomada de crédito para construir novos empreendimentos.',
  },
  {
    image: 'new-partner',
    title: 'Novo parceiro',
    text: 'Oportunidade de agregar novos produtos ao seu negócio com ótimas perspectivas de crescimento.',
  },
];
