import {Component, Input} from '@angular/core';
import {Testimony} from 'src/app/models/testimony.model';

@Component({
  selector: 'app-testimony-card',
  templateUrl: './testimony-card.component.html',
  styleUrls: ['./testimony-card.component.scss']
})
export class TestimonyCardComponent {

  @Input()
  testimony!: Testimony;

  get image(): string {
    return `../../../assets/imgs/${this.testimony.profileImage}.webp`;
  }

}
