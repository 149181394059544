import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { Observable } from 'rxjs';

import { environment } from '../environments/environment';

@Injectable()
export class Interceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)) {
        // Clone the request to add the new header
        const clonedRequest = request.clone({ headers: request.headers.append('x-tenant-id', environment.tenant) });

        // Pass the cloned request instead of the original request to the next handle
        return next.handle(clonedRequest);
    } else {
        return next.handle(request);
    }
  }
}
