<div class="card-container flex-item purple-400">
  <div class="card-div-style">
    <p class="title white ">
      {{ advantage.title }}
    </p>
    <p class="subtitle white">
      {{ advantage.subtitle }}
    </p>
  </div>
</div>
