<main class="office">
  <div class="credi-container">
    <div class="horizontal-size">
      <h1 class="purple-400">Aqui somos pró-negócio</h1>
      <h5 class="default-text blue-1000">
        <br>Geramos soluções personalizadas que cabem na realidade dos nossos parceiros.<br><br>
        Propomos conectar as melhores soluções, criando oportunidades de desenvolvimento
        empresarial, crescimento profissional e sucesso financeiro.<br><br>
        Tudo de forma estratégica, sustentável e personalizável.
      </h5>
    </div>
  </div>
</main>

<section class="credi-container credi-center f-align-left f-column p-top-15-bottom-15">
  <h2 class="purple-400 m-bottom-64">Por que ser um parceiro Crediblue?</h2>
  <div class="flex-item f-center f-justify-left f-gap-24">
    <ng-container *ngFor="let advantage of advantages">
      <app-advantage-card [advantage]="advantage" color="purple"></app-advantage-card>
    </ng-container>
  </div>
  <div class="responsive-button-size">
    <a class="flat blue-600 m-top-64 max-width animated" routerLink="/be-partner">SEJA UM PARCEIRO AGORA MESMO</a>
  </div>
</section>

<section class="purple-400 p-vertical-horizontal-50">
  <div class="credi-container">
    <h2 class="white">Inovação.</h2>
    <h2 class="white m-left-132 responsive-margin-left">Confiança.</h2>
    <span class="divider"></span>
    <div class="buttons-size">
      <ng-container class="align-buttons" *ngFor="let advantage of partnerTechAdvantages">
        <button class="align-buttons"
                [class]="advantage.type == currentType
                ? 'shadow-text active'
                : 'shadow-text' "
                (click)="changeCurrentData(advantage.type)">{{ advantage.title }}
        </button>
      </ng-container>
    </div>
    <div class="flex-item f-center flex-responsive f-align-top f-gap-68 m-top-64">
      <div [class]="currentTypeData.image.concat(' f-50 bg-image b-radius-8')"></div>
      <div class="f-50 align-section-text">
        <h3 class="text-shadow-blue blue-100 m-bottom-36">{{ currentTypeData.title }}</h3>
        <ul>
          <li class="white text-shadow-blue" *ngFor="let advantage of currentTypeData.advantages">{{ advantage }}</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<section class="credi-container credi-center flex-item m-top-108 f-gap-24">
  <ng-container *ngFor="let benefit of benefits">
    <app-option-card [advantage]="benefit"></app-option-card>
  </ng-container>
</section>
<section class="credi-container credi-center m-top-36 p-vertical-64-horizontal-0">
  <app-newsletter-form></app-newsletter-form>
</section>
