import {Component, Input} from '@angular/core';
import {Report} from 'src/app/models/report.model';

@Component({
  selector: 'app-report-card',
  templateUrl: './report-card.component.html',
  styleUrls: ['./report-card.component.scss']
})
export class ReportCardComponent {

  @Input()
  report!: Report;

  get image(): string {
    return `card-img ${this.report.logo}`;
  }

}
