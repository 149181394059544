import { Testimony } from "../models/testimony.model";

export const testimonies: Testimony[] = [
    {
        name: 'Gardênia Alves',
        role: 'Head of Receivables Management',
        testimony: '“Trabalhar na Blueway Capital tem sido uma experiência incrível e inovadora, pois a liberdade que temos com a gestão horizontal nos deixa agir com mais autonomia, trazendo motivação e muito crescimento, tanto profissional quanto pessoal. Tenho evoluído a cada dia com experiência que só a Blue nos proporciona!”',
        profileImage: 'gardenia'
    },
    {
        name: 'Matheus Lima',
        role: 'Account Manager',
        testimony: '“Fazer parte do time da Crediblue, me traz uma gratidão imensa! Aqui somos desafiados a cada dia para sermos melhores, vencer obstáculos, buscar sonhos e ter coragem para enfrentar qualquer batalha! Além de acreditar na cultura e nos valores da empresa, tenho muita admiração e orgulho das pessoas que trabalham comigo, o sentimento de pertencimento e de que juntos iremos mais longe, é o que alimente minha paixão pela empresa.”',
        profileImage: 'matheus'
    },
    {
        name: 'Ana Laura Colombi',
        role: 'Head of Backoffice',
        testimony: '“Ter passado pelos mais diversos desafios, desde a época de estágio permite que hoje eu conheça as dores e os encantos dos processos. O meu cargo ocupacional nunca limitou minhas entregas e a experiência vivência foi crucial para que conseguisse chegar até aqui. A Crediblue está acima da média, aqui é despertado o orgulho em pertencer.',
        profileImage: 'ana'
    },
];
