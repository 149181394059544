import {Injectable, Injector} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {PaginatorModel} from "../models/paginator.model";
import {UtilComponent} from "../util/util.component";
import { Enum } from '../data/enum.model';

@Injectable({
  providedIn: 'root'
})
export class GenericEnumService extends UtilComponent {

  constructor(private http: HttpClient,
              injector: Injector) {
    super(injector);
  }

  public findEnumsByName(enumTypeName: string): Observable<Enum[]> {
    return this.http.get<Enum[]>(`${environment.apiUrl}/enums/${enumTypeName}`);
  }
}
