import {Component, Injector, OnInit} from '@angular/core';
import {Report} from 'src/app/models/report.model';
import {crediblueReports} from 'src/app/data/crediblue-reports.data';
import {UtilComponent} from "../../util/util.component";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends UtilComponent implements OnInit {

  reports: Report[] = crediblueReports;

  isCollapsed = true;

  numeroReportagensExibidas = 6;

  constructor(
    injector: Injector
  ) {
    super(injector);
  }

  ngOnInit() {
    this.setPageTitleAndDescription();
  }

  setPageTitleAndDescription() {
    this.customizeMetatags(
      'Home | Crediblue',
      'A Crediblue é uma fintech especializada em soluções digitais para a oferta de crédito imobiliário. Sua rede de parceiros atua em todo o Brasil.'
    );
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

}
