import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-politics-document',
  templateUrl: './politics-document.component.html',
  styleUrls: ['./politics-document.component.scss']
})
export class PoliticsDocumentComponent {

  @Input()
  partnerName = 'Blue Tecnologia';

  constructor() {
  }
}
