<nav id="site-header" class="navbar navbar-expand-md fixed-top nav-bar">
  <div class="credi-container header-container">
    <a class="navbar-brand nav-icon" routerLink="" routerLinkActive="active">
      <app-cred-icon
        name="crediblue-logo"
        size="n1">
      </app-cred-icon>
    </a>

    <div class="mobile-menu-wrapper pos-relative">
      <button class="burger-menu-svg" (click)="changeMobileMenuState()">
        <img alt="ícone de menu com três listras na vertical" src="assets/imgs/burger-menu-svgrepo-com.svg" #toggleButton>
      </button>
    </div>

    <ul class="navbar-nav flex-item f-center">
      <li class="nav-item nav-link">
        <a class="nav-link nav-bar-text" routerLink="/home" routerLinkActive="active-link">A Crediblue</a>
      </li>

      <div class="dropdown nav-item nav-link" dropdown #dropdown="bs-dropdown" role="menu">
        <div class="dropdown-toggle nav-link nav-bar-text"
             dropdownToggle
             routerLinkActive="">
          <a
            class="block-click"
            routerLink="solutions">
          </a>
          Transparência
        </div>

        <div class="dropdown-menu mt-3 transparency" *dropdownMenu>
          <a class="dropdown-item b-top-radius-3px" href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/politica-de-privacidade-de-dados-crediblue.pdf" target="_blank" >
            Política de Privacidade
          </a>
          <span></span>
          <a class="dropdown-item" href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/Código_de_Ética_e_Conduta_-_Blueway.pdf" target="_blank">
            Código de Ética
          </a>
        </div>
      </div>

      <div class="dropdown nav-item nav-link" dropdown #dropdown="bs-dropdown" role="menu">
        <div class="dropdown-toggle nav-link nav-bar-text"
             dropdownToggle
             routerLinkActive="active-link">
          <a
            class="block-click"
            routerLink="solutions">
          </a>
          Nossas Soluções
        </div>
        <div class="dropdown-menu mt-3 solutions" *dropdownMenu>
          <a class="dropdown-item b-top-radius-3px" routerLink="solutions/credit-type">
            Tipos de Crédito
          </a>
          <span></span>
          <a class="dropdown-item" routerLink="solutions/for-partners">
            Para Parceiros
          </a>
          <span></span>
          <a class="dropdown-item b-bottom-radius-3px" routerLink="solutions/simulator">
            Simulador
          </a>
        </div>
      </div>

      <li class="nav-item nav-link">
        <a class="nav-link nav-bar-text" routerLink="/help" routerLinkActive="active-link">Ajuda</a>
      </li>
      <li class="nav-item nav-link">
        <a class="nav-link nav-bar-text" routerLink="/careers" routerLinkActive="active-link">Carreiras</a>
      </li>
      <li class="nav-item nav-link">
        <a class="nav-link nav-bar-text" target="_blank" href="https://docs.azo.blue"
           routerLinkActive="active-link">Para Desenvolvedores</a>
      </li>
      <li class="nav-item nav-link">
        <a class="nav-link nav-bar-text" routerLink="/blog" routerLinkActive="active-link">Blog</a>
      </li>
      <a class="flat purple-400-s purple-border m-left-18" target="_blank" href="https://crediblue.azo.blue/"
        >ENTRAR</a>
      <a class="flat purple-400 m-left-12" routerLink="/be-partner">SEJA PARCEIRO</a>
    </ul>
  </div>
</nav>
