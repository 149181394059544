import { HelpTopic } from "../models/help-tab.model";

export const helpTabData: HelpTopic[] = [
    {
        topic: 'for-partners',
        title: 'PARA PARCEIROS',
        questions: [
            {
                question: 'Qual a vantagem de ser um parceiro Crediblue?',
                answer: 'O parceiro Crediblue conta com uma solução completa para oferta de crédito, com plataforma de gestão de operações, landing page para prospecção de novos clientes e gerente de contas que o acompanha em todas as etapas. <br>Além da nossa capacidade de oferecer crédito e viabilizar sonhos, nosso modelo de parceria conta com um success fee, em que cada parceiro determina o valor do seu trabalho.'
            },
            {
                question: 'Pessoa física pode ser parceiro Crediblue?',
                answer: 'Sim! A parceria Crediblue pode ser feita tanto para pessoas jurídicas quanto para pessoas físicas. E não se preocupe: mesmo sendo pessoa física, nós criamos uma identidade visual só sua!'
            },
            {
                question: 'O parceiro tem que cobrar os pagamentos das parcelas junto ao cliente?',
                answer: 'Não! A Crediblue é a responsável por disponibilizar os boletos de pagamento, bem como fazer as devidas cobranças e dar o suporte ao cliente. '
            },
            {
                question: 'O modelo de parceria da Crediblue exige exclusividade?',
                answer: 'Não! No nosso modelo de parceria, não é necessário ter exclusividade. Você pode trabalhar com outras empresas e fintechs de soluções financeiras. '
            },
        ]
    },
    {
        topic: 'credit',
        title: 'CRÉDITO',
        questions: [
            {
                question: 'O que é home equity?',
                answer: 'O <i>home equity</i>, ou Crédito com Garantia Imobiliária (CGI), é uma modalidade de crédito em que o cliente consegue até 60% do valor de avaliação do imóvel para ser utilizado como preferir. '
            },
            {
                question: 'Quais as vantagens de home equity?',
                answer: 'Em uma operação de crédito, quando é colocado uma garantia real, os juros cobrados pelas instituições ficam muito mais baixos, justamente pelo fato de a instituição credora ter uma garantia, caso o credor não consiga honrar com os compromissos do crédito. '
            },
            {
                question: 'O que é o financiamento imobiliário?',
                answer: 'Financiamento imobiliário é uma modalidade de crédito em que uma instituição credora paga parte do imóvel para a parte vendedora e o contratante de crédito honra os compromissos financeiros com a instituição credora. <br>Nessa modalidade, os recursos financeiros são utilizados para um objeto predefinido e acordado pelas partes envolvidas. O imóvel comprado através do financiamento é de posse do comprador, mas não pode ser negociado, vendido ou alienado sem que a dívida com a instituição credora seja paga.'
            },
            {
                question: 'O que é o crédito para construção?',
                answer: 'O crédito para construção é uma modalidade de operação em que o cliente consegue até 80% do valor do seu imóvel pronto! Nessa modalidade, o crédito é liberado sob medição, ou seja, o cliente recebe os recursos financeiros de acordo com o andamento da obra. <br>O imóvel que será construído é a garantia utilizada na alienação fiduciária e fica em nome da instituição credora até a quitação final do contrato.'
            },
        ]
    },
    {
        topic: 'for-clients',
        title: 'PARA CLIENTES',
        questions: [
            {
                question: 'O que é a Crediblue?',
                answer: 'A Crediblue é uma empresa especializada na área de serviços financeiros com processos baseados em tecnologia, que viabiliza soluções estratégicas de crédito facilitadas e personalizadas. Combinando, assim, tecnologia à expertise no mercado financeiro e imobiliário, além de possuir uma extensa rede de parceiros por todo país. <br>A empresa, integrante do ecossistema de capitais Blueway Capital, disponibiliza uma plataforma própria e gratuita para seus parceiros utilizarem, de forma que possam levar a seus clientes melhores ofertas de crédito, com taxas flexíveis, análises ágeis e avaliando os imóveis em todo território nacional.'
            },
            {
                question: 'A Crediblue é um banco?',
                answer: 'Não, a Crediblue não realiza qualquer atividade privativa de instituições financeiras. A Crediblue é uma correspondente bancária, que disponibiliza uma plataforma própria e gratuita para seus parceiros utilizarem. <br>Isso para que possam levar a seus clientes melhores ofertas de crédito, com taxas flexíveis, análises ágeis e avaliando os tipos de imóveis em todo território nacional.'
            },
            {
                question: 'Como posso entrar em contato com a Crediblue?',
                answer: 'Você pode entrar em contato conosco através do e-mail <a class="grey-500" href="mailto:contato@crediblue.com"><strong>contato@crediblue.com.br</strong></a> ou telefone <strong>(62) 3414-7645.</strong>'
            },
            {
                question: 'O que é o crédito para construção?',
                answer: 'Sim! Estamos atentos a todos os direitos e obrigações previstas na Lei Geral de Proteção de Dados (Lei 13.709/18). Para mais informações, acesse nossa <a><strong>Política de Privacidade de Dados.</strong></a> '
            },
        ]
    },
]
