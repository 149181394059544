<h4  [innerHTML]="topic.question"></h4>
<section class="expandable">
  <section class="expandable-inner">
    <p class=" grey-500 m-top-36" [innerHTML]="topic.answer"></p>
  </section>
</section>
<button (click)="changeState()">
  <app-cred-icon  class="plus"  name="plus" size="n6"></app-cred-icon>
  <app-cred-icon  class="minus" name="minus" size="n6"></app-cred-icon>
</button>
