import {Component, Input} from '@angular/core';
import {Testimony} from 'src/app/models/testimony.model';

@Component({
  selector: 'app-home-testimony-card',
  templateUrl: './home-testimony-card.component.html',
  styleUrls: ['./home-testimony-card.component.scss']
})
export class HomeTestimonyCardComponent {

  @Input()
  content!: Testimony;

  get image(): string {
    return `f-25 testimony-img ${this.content.profileImage}`;
  }

}
