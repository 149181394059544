import {Component, Injector, OnInit} from '@angular/core';
import { crediblueValues } from 'src/app/data/crediblue-values.data';
import { testimonies } from 'src/app/data/testimonies.data';
import { MainContent } from 'src/app/models/contents.model';
import { Testimony } from 'src/app/models/testimony.model';
import {UtilComponent} from "../../util/util.component";

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent extends UtilComponent implements OnInit {

  values!: MainContent[];

  testimonies!: Testimony[];

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.setPageTitleAndDescription();
    this.values = crediblueValues;
    this.testimonies = testimonies;
  }

  setPageTitleAndDescription() {
    this.customizeMetatags(
      'Carreiras | Crediblue',
      'Quer fazer parte do nosso time? Conheça nossas oportunidades.'
    );
  }
}
