import {Component, Input} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-blue-input',
  templateUrl: './blue-input.component.html',
  styleUrls: ['./blue-input.component.scss']
})
export class BlueInputComponent {

  @Input()
  label!: string;

  @Input()
  control!: FormControl;

}
