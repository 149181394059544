import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

declare const getFP: any;

@Injectable({
  providedIn: 'root'
})
export class IpServiceService {

  constructor(private http: HttpClient) { }

  public getIpAddress(): Observable<{ ip: string}> {
    return this.http.get<{ ip: string}>("https://api.ipify.org/?format=json");
  }

  public getFingerprint() {
    const options = { log: false };

    return getFP(options);
  }
}
