import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component} from '@angular/core';
import {homeCardsContent} from 'src/app/data/home-cards.data';
import {HomeCards} from 'src/app/models/contents.model';

@Component({
  selector: 'app-home-slider',
  animations: [
    trigger('scrollLeft', [
      state('position1', style({
        left: 0
      })),
      state('position2', style({
        left: '-16%'
      })),
      state('position3', style({
        left: '-33%'
      })),
      state('position4', style({
        left: '-50%'
      })),
      state('position5', style({
        left: '-66%'
      })),
      state('position6', style({
        left: '-75%'
      })),
      transition('* => *', [
        animate('0.2s')
      ]),
    ])
  ],
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss']
})
export class HomeSliderComponent {

  contents: HomeCards[] = homeCardsContent;

  position: string = '1';

  onClickChange(number: number): void {
    let actual = Number(this.position);
    actual += number;
    this.position = `${actual}`
  }

  onClickDotChange(number: number): void {
    this.position = `${number}`
  }

  isActive(number: Number): string {
    return Number(this.position) == number ? 'active' : '';
  }

}
