import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {PoliticsDocumentComponent} from './politics-document/politics-document.component';
import {EmbeddedSimulatorComponent} from './simulator/embedded-simulator/embedded-simulator.component';
import {GenericModalComponent} from './modal/generic-modal/generic-modal.component';
import {SafePipe} from "../pipes/safe.pipe";
import {AdvantageCardComponent} from "./cards/advantage-card/advantage-card.component";
import {HomeCardComponent} from "./cards/home-card/home-card.component";
import {HomeTestimonyCardComponent} from "./cards/home-testimony-card/home-testimony-card.component";
import {OptionCardComponent} from "./cards/option-card/option-card.component";
import {ReportCardComponent} from "./cards/report-card/report-card.component";
import {StrategyCardComponent} from "./cards/strategy-card/strategy-card.component";
import {TestimonyCardComponent} from "./cards/testimony-card/testimony-card.component";
import {ValuesCardComponent} from "./cards/values-card/values-card.component";
import {NewsletterFormComponent} from "./credi-form/newsletter-form/newsletter-form.component";
import {NewsletterFormSmallComponent} from "./credi-form/newsletter-form-small/newsletter-form-small.component";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {CredIconComponent} from "./icons/cred-icon/cred-icon.component";
import {BlueCheckboxComponent} from "./inputs/blue-checkbox/blue-checkbox.component";
import {BlueSearchInputComponent} from "./inputs/blue-search-input/blue-search-input.component";
import {BlueInputComponent} from "./inputs/blue-input/blue-input.component";
import {ReactiveFormsModule} from "@angular/forms";
import {TestimonySliderComponent} from "./sliders/testimony-slider/testimony-slider.component";
import {HomeSliderComponent} from "./sliders/home-slider/home-slider.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {HelpPanelComponent} from "./panels/help-panel/help-panel.component";
import {NgbDropdown} from "@ng-bootstrap/ng-bootstrap";
import { ExpandableDirective } from '../directives/expandable/expandable.directive';
import { ExpandableInnerDirective } from '../directives/expandable/expandable-inner.directive';
import { CrediblueMobileMenuComponent } from './crediblue-mobile-menu/crediblue-mobile-menu.component';
import { CpfDirective } from '../directives/cpf.directive';
import { CnpjDirective } from '../directives/cnpj.directive';
import { PostCardComponent } from './cards/post-card/post-card.component';
import { TruncatePipe } from '../pipes/truncate.pipe';
import { RecaptchaModule } from 'ng-recaptcha';
import { CrediBlockUiComponent } from './credi-block-ui/credi-block-ui.component';
import { SpinnerComponent } from './spinner.component';

@NgModule({
  declarations: [
    FooterComponent,
    HeaderComponent,
    PoliticsDocumentComponent,
    EmbeddedSimulatorComponent,
    GenericModalComponent,
    SafePipe,
    AdvantageCardComponent,
    HomeCardComponent,
    HomeTestimonyCardComponent,
    OptionCardComponent,
    ReportCardComponent,
    StrategyCardComponent,
    TestimonyCardComponent,
    ValuesCardComponent,
    NewsletterFormComponent,
    NewsletterFormSmallComponent,
    BlueCheckboxComponent,
    BlueSearchInputComponent,
    BlueInputComponent,
    TestimonySliderComponent,
    HomeSliderComponent,
    CredIconComponent,
    HelpPanelComponent,
    ExpandableDirective,
    ExpandableInnerDirective,
    CrediblueMobileMenuComponent,
    CpfDirective,
    CnpjDirective,
    PostCardComponent,
    TruncatePipe,
    CrediBlockUiComponent,
    SpinnerComponent
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    BsDropdownModule,
    NgbDropdown,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-right'
    }),
    RecaptchaModule
  ],
  exports: [
    FooterComponent,
    HeaderComponent,
    PoliticsDocumentComponent,
    EmbeddedSimulatorComponent,
    GenericModalComponent,
    SafePipe,
    AdvantageCardComponent,
    HomeCardComponent,
    HomeTestimonyCardComponent,
    OptionCardComponent,
    ReportCardComponent,
    StrategyCardComponent,
    TestimonyCardComponent,
    ValuesCardComponent,
    NewsletterFormComponent,
    NewsletterFormSmallComponent,
    BlueCheckboxComponent,
    BlueSearchInputComponent,
    BlueInputComponent,
    TestimonySliderComponent,
    HomeSliderComponent,
    CredIconComponent,
    ToastrModule,
    HelpPanelComponent,
    ExpandableDirective,
    ExpandableInnerDirective,
    CrediblueMobileMenuComponent,
    CpfDirective,
    CnpjDirective,
    PostCardComponent,
    TruncatePipe,
    CrediBlockUiComponent,
    SpinnerComponent
  ]
})
export class ComponentsModule {
}
