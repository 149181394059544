import {Component, Injector, OnInit} from '@angular/core';
import { CreditTypeTemplateData } from 'src/app/data/credit-types.data';
import { CreditTypeTemplate, CreditTypes } from 'src/app/models/credit-types.model';
import {UtilComponent} from "../../util/util.component";

@Component({
  selector: 'app-credit-type',
  templateUrl: './credit-type.component.html',
  styleUrls: ['./credit-type.component.scss']
})
export class CreditTypeComponent extends UtilComponent implements OnInit {

  creditTypes!: CreditTypeTemplate[];

  currentTypeData!: CreditTypeTemplate;

  currentType!: CreditTypes;

  constructor(injector: Injector) {
    super(injector);
  }

  ngOnInit(): void {
    this.setPageTitleAndDescription();
    this.creditTypes = CreditTypeTemplateData;
    this.currentTypeData = this.creditTypes[0];
    this.currentType = 'he';
  }

  changeCreditType(type: CreditTypes): void {
    this.currentType = type;
    this.currentTypeData = this.creditTypes.filter(val => val.creditType == type)[0];
  }

  getClass(isMainContent: boolean): string {
    return isMainContent
      ? `flex-item f-50 main-image ${this.currentTypeData.mainContent.image}`
      : `flex-item f-50 disclaimer-img ${this.currentTypeData.disclaimer.image}`;
  }

  setPageTitleAndDescription() {
    this.customizeMetatags(
      'Tipos de Crédito | Crediblue',
      'Ofereça opções estratégicas para os seus clientes.'
    );
  }
}
