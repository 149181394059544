import {Component, Input} from '@angular/core';
import {TitleAndSubtitle} from 'src/app/models/contents.model';

@Component({
  selector: 'app-option-card',
  templateUrl: './option-card.component.html',
  styleUrls: ['./option-card.component.scss']
})
export class OptionCardComponent {

  @Input()
  advantage!: TitleAndSubtitle;

}
