import {Testimony} from "../models/testimony.model";

export const homeTestimonyData: Testimony[] = [
  {
    name: 'Roberto Bastos',
    role: 'Sócio na Ferraz Consultoria Empresarial',
    testimony: '“Conheci a Crediblue através do vasto networking que o mercado imobiliário nos oferece. ' +
      'Cheguei até ela por meio de uma parceria comercial, algo que valorizo em todos os negócios. ' +
      'Tenho mais de dez anos de experiência no mercado ' +
      'financeiro, sendo 8 anos em bancos como Gerente PJ, segmento Middle, e há ' +
      'quatro anos como empresário exercendo consultoria e assessoria de projetos ' +
      'financeiros. Atualmente, tenho como objetivo captar as melhores condições de ' +
      'crédito do mercado que atendam a cada necessidade específica. Hoje, ' +
      'a Crediblue é o meu principal parceiro comercial para levar aos clientes de forma ' +
      'rápida e dinâmica a obtenção de recursos. Nossos negócios geraram ' +
      'um aumento de 70% na minha receita nos últimos 12 meses! O grande ' +
      'diferencial da nossa parceria é o rápido tempo de retorno e flexibilidade em ' +
      'análises de crédito, além da acessibilidade de comunicação com toda equipe, o ' +
      'que transforma o processo mais ágil,sistema operacional eficiente e simples de ' +
      'manuseio. Sei que o mercado está aberto a novas oportunidades e novas ' +
      'parcerias sempre são bem-vindas. Faço a minha indicação da Crediblue para ' +
      'outros empreendedores, pois vejo como excelente oportunidade de crescimento para todos.“',
    profileImage: 'partner1'
  },
  {
    name: 'Renato Reis',
    role: 'Sócio-fundador da Prata Capital e Escritura Fácil',
    testimony: '“O que falar de uma parceria que é mais que isso? Na Crediblue, nós da ' +
      'PrataCapital e do Escritura Fácil nos sentimos literalmente em casa. É uma ' +
      'extensão da nossa empresa, da nossa família. Crescemos e aprendemos juntos. ' +
      'Um dia dinâmico e leve. Ajudamos nossos clientes, fazemos negócios e ' +
      'ganhamos dinheiros juntos. Somos só gratidão por essa parceria.”',
    profileImage: 'partner4'
  },
  {
    name: 'Rafael Almeida',
    role: 'Sócio na Acredite',
    testimony: '“A Crediblue é uma empresa séria, que trabalha próximo de seus parceiros. ' +
      'Objetividade e persistência em analisar as operações são características fortes ' +
      'da empresa. Nós, da Acredite, agradecemos a parceria e desejamos que continue ' +
      'sendo um sucesso.“',
    profileImage: 'partner3'
  },
  {
    name: 'Paula Suasunna',
    role: 'Sócia na PS Finance',
    testimony: '“A Crediblue pra mim hoje é meu principal parceiro. ' +
      'É uma empresa que tem transparência e que entende o negócio do cliente e não perdem oportunidades, além do atendimento ao parceiro que é excelente. ' +
      'Existe diálogo em todos os momentos. Você não é só mais um como na concorrência. ' +
      'Só tenho pontos positivos a respeito dessa instituição, que já é um sucesso!”',
    profileImage: 'partner2'
  },
]
