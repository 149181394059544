<section>
  <div class="credi-container">
    <h1 class="purple-400 padding-bottom">Seja um Parceiro</h1>
    <h5 class="blue-1000">Venha impulsionar negócios. Cadastre como Parceiro Crediblue agora mesmo.</h5>
  </div>
  <div class="credi-container opportunity-container">
    <div class="opportunity-container__card">
      <div class="card-header">
        <h5 class="purple-400">Cadastrar</h5>
        <button *ngIf="formType$ | async" (click)="setFormType()" class="btn-go-back">
          <app-cred-icon name="arrow-left" size="n3"></app-cred-icon>
        </button>
      </div>
      <form *ngIf="bePartnerForm" [formGroup]="bePartnerForm" (ngSubmit)="onSubmit()">
        <ng-container *ngIf="!(formType$ | async); else wasPersonTypeSelected">
          <p>Você possui CNPJ?</p>

          <div class="has-cnpj-buttons">
            <button class="flat blue-600" (click)="setFormType('CPF')">Não</button>
            <button class="flat blue-1000" (click)="setFormType('CNPJ')">Sim</button>
          </div>
        </ng-container>

        <ng-template #wasPersonTypeSelected>
          <ng-container *ngIf="(formType$ | async) === 'CPF'; else companyForm">
            <div>
              <label>CPF</label>
              <input type="text" placeholder="Digite seu CPF" id="taxIdPf" cpfMask formControlName="taxId" class="field-input flex-item w-100" required>
              <em *ngIf="bePartnerForm.controls['taxId']?.touched && bePartnerForm.controls['taxId']?.invalid" class="invalid">
                CPF inválido
              </em>
            </div>
            <div>
              <label>Nome Completo</label>
              <input type="text" placeholder="Digite seu Nome Completo" id="name" formControlName="name" class="field-input">
            </div>
          </ng-container>
          <ng-template #companyForm>
            <div>
              <label>CNPJ</label>
              <input placeholder="Digite seu CNPJ" id="taxIdPj" cnpjMask formControlName="taxId" class="field-input flex-item w-100" required>
              <em *ngIf="bePartnerForm.controls['taxId']?.touched && bePartnerForm.controls['taxId']?.invalid" class="invalid">
                CNPJ inválido
              </em>
            </div>
            <div>
              <label>Razão Social</label>
              <input type="text" placeholder="Digite sua Razão Social" id="corporateName" formControlName="corporateName" class="field-input">
            </div>
            <div>
              <label>Nome Fantasia</label>
              <input type="text" placeholder="Digite seu Nome Fantasia" id="fantasyName" formControlName="fantasyName" class="field-input">
            </div>
          </ng-template>

          <div>
            <label>Email</label>
            <input type="email" placeholder="Digite seu Email" id="email" formControlName="email" minlength="6" required class="field-input">
          </div>

          <div>
            <label>Whatsapp</label>
            <input type="phone" placeholder="Digite seu Whatsapp" id="whatsapp" formControlName="whatsapp" mask="(00) 00000 0000" required class="field-input">
            <em *ngIf="bePartnerForm.controls['whatsapp']?.touched && bePartnerForm.controls['whatsapp']?.invalid" class="invalid">
              Número inválido
            </em>
          </div>

          <div class="accept-term__list">
            <ng-container *ngFor="let termForm of bePartnerForm?.controls?.['acceptedPersonaTerms']?.['controls']; let i = index ;" [formGroup]="termForm">
              <div class="accept-term__list__item">
                <input type="checkbox" class="f-04" [id]="'termItem-' + i" formControlName="isAccepted" required checked>
                <label [for]="'termItem-' + i" class="f-96 padding-left-small">
                  <small>Li e aceito a(o) <a type="button" (click)="openTermView(termForm.value['term'])"> <a href="javascript:;"> {{getTermDescriptionByType(termForm.value['term'])}}</a></a></small>
                </label>
              </div>
            </ng-container>
          </div>

          <button class="submit-button-form" id="submit" type="submit"
            [disabled]="bePartnerForm?.invalid">Enviar
          </button>
        </ng-template>
      </form>
    </div>

    <div class="opportunity-container__image">
      <img src="assets/imgs/blond-woman.webp" alt="Woman Smille">
    </div>
  </div>
</section>

