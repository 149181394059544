import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import { Offer } from '../models/offer.model';
import { Term } from '../models/term.model';

@Injectable({
  providedIn: 'root'
})
export class TermService {
  urlBase: string;
  constructor(private http: HttpClient) {
    this.urlBase = `${environment.apiUrl}/terms`
  }

  public getCurrentTermByTargetEntityAndType(targetEntity: string, termType: string): Observable<Term> {
    return this.http.get<Term>(`${environment.apiUrl}/terms/${targetEntity?.toUpperCase()}/${termType?.toUpperCase()}`);
  }

  public getTermByTargetEntity(targetEntity: string): Observable<Term[]> {
    return this.http.get<Term[]>(`${environment.apiUrl}/terms/${targetEntity?.toUpperCase()}/all`);
  }
}
