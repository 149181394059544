import { Component, Injector, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DialogWarrantyAlertComponent } from '../dialog-warranty-alert/dialog-warranty-alert.component';
import { LandingPageDataResponse } from '../../../models/landing-page-data-response.model';
import { Offer } from 'src/app/models/offer.model';
import { ActivatedRoute } from '@angular/router';
import { OportunityService } from 'src/app/services/oportunity.service';
import { forkJoin } from 'rxjs';
import { OfferService } from 'src/app/services/offer.service';
import { UtilComponent } from 'src/app/util/util.component';
import { DialogdialogRedirectingToSimulatorComponent } from '../dialog-redirecting-to-simulator/dialog-redirecting-to-simulator.component';
import { IpServiceService } from 'src/app/services/ip-service.service';
import { Term } from 'src/app/models/term.model';
import { IConfigurationTenant } from 'src/app/models/configuration-tenant.model';
import { TermService } from 'src/app/services/term.service';
import { Enum } from 'src/app/data/enum.model';
import { DialogTermComponent } from 'src/app/components/modal/dialog-term/dialog-term.component';
import { GenericEnumService } from 'src/app/services/generic-enum.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-page-qualification-offer',
  templateUrl: './page-qualification-offer.component.html',
  styleUrls: ['./page-qualification-offer.component.scss']
})
export class PageQualificationOfferComponent extends UtilComponent implements OnInit {
  whatsappMessageUrl = "https://api.whatsapp.com/send/";
  crediblueWhatsappPhoneNumber =  "556234147645";

  wasAgreededTerms = new FormControl(false);
  wasCanceledConfirmed = false;
  hasWarranty = new FormControl();

  offer: Offer;
  landingPageDataResponse: LandingPageDataResponse;
  tenantConfiguration: IConfigurationTenant;

  termToOfferAccept: Term[];
  clientOriginIp: string | any;
  clientOriginFingerprint: string | any;
  dataSnapshotForTermRendering: string | any;

  termTypeList: Enum[];

  constructor(
    injector: Injector,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private partnerService: OportunityService,
    private offerService: OfferService,
    private termService: TermService,
    private ipService: IpServiceService,
    private enumService: GenericEnumService,
    private notificationService: NotificationService
  ) {
    super(injector);
  }

  async ngOnInit() {
    this.blockUI.start();
    this.loadEnums();
    this.clientOriginIp = ((await this.ipService.getIpAddress().toPromise())?.ip);
    this.clientOriginFingerprint = this.ipService.getFingerprint();

    this.activatedRoute.params.subscribe(allParams => {
      const { offerId, partnerLink } = allParams;

      forkJoin({
        offerFound: this.offerService.findProposalOfferById(offerId),
        partnerFound: this.partnerService.findLandingPageDataByLink(partnerLink),
        tenantConfiguration: this.oportunityService.getCurrentTenant(),
        sharingDataWithPartnerTerm: this.termService.getTermByTargetEntity('LEAD')
      }).subscribe(result => {
        this.offer = result.offerFound;
        this.landingPageDataResponse = result.partnerFound;
        this.tenantConfiguration = result.tenantConfiguration;
        this.termToOfferAccept = result.sharingDataWithPartnerTerm;
        this.dataSnapshotForTermRendering = this.buildDataSnapshotForTermRendering(this.landingPageDataResponse, this.tenantConfiguration);

        this.blockUI.stop();
      }, err => {
        this.blockUI.stop();
        console.error(err);
        this.redirectToSiteHome();
      });
    });
  }

  public get proposalOfferIdentifier(): string {
    const proposalOfferId = this.offer?.idProposta;
    const partnerOriginatorLink =  "BNDES";

    return `#${partnerOriginatorLink}-${proposalOfferId}`;
  }

  private buildWhatsappSimulatorUrl(): string {
    const message = encodeURIComponent(`Codigo da Proposta ${this.proposalOfferIdentifier}`);
    return `${this.whatsappMessageUrl}?phone=${this.crediblueWhatsappPhoneNumber}&text=${message}`;
  }

  public get wasAgreededTermsValue() {
    return this.wasAgreededTerms.value;
  }

  public get hasWarrantyValue() {
    return this.hasWarranty.value;
  }

  public showCopiedContentMessage(): void {
    this.notificationService.showSuccess("Identificador Copiado para Área de Transferência", "Feito!")
  }

  public continueRequest(byWhatsapp: boolean = false): void {
    if(this.offer.status == "LEAD") {
      this.continueSimulation(byWhatsapp);
    } else {
      this.saveLeadAndTermsAgreeAndRedirectToContinueSimulation(byWhatsapp);
    }
  }

  private saveLeadAndTermsAgreeAndRedirectToContinueSimulation(byWhatsApp: boolean) {
    this.blockUI.start();
    this.offerService.confirmOfferTermsAgree(
      this.offer.idOferta,
      this.landingPageDataResponse.partnerId,
      this.termToOfferAccept,
      this.clientOriginIp,
      this.clientOriginFingerprint,
      this.dataSnapshotForTermRendering
    ).subscribe(updatedOffer => {
      this.offer = updatedOffer;
      this.blockUI.stop();
      this.continueSimulation(byWhatsApp);
    }, err => {
      this.notifyService.showError(err.error?.message, "Ops!");
      this.blockUI.stop();
    });
  }

  private continueSimulation(byWhatsApp: boolean): void {
    this.dialog.open(DialogdialogRedirectingToSimulatorComponent, {
      width: '25rem',
      data: {
        urlToRedirect: byWhatsApp ? this.buildWhatsappSimulatorUrl() : this.offer.urlLandingPage,
        byWhatsApp
      }
    });
  }

  public openConfirmationCancelDialog() {
    const dialogRef = this.dialog.open(DialogWarrantyAlertComponent, {
      width: '25rem',
      data: {
        offer: this.offer,
        partner: this.landingPageDataResponse
      }
    });

    dialogRef.afterClosed().subscribe(wasCancelConfirmed => {
      if(wasCancelConfirmed) {
        this.blockUI.start();
        this.offerService.cancelOffer(this.offer.idOferta).subscribe(() => {
          this.notifyService.showSuccess("Cancelamento realizado, com sucesso!", "Feito!");
          this.wasCanceledConfirmed = true;
          this.blockUI.stop();
        }, err => {
          this.notifyService.showError(err.error?.message, "Ops!");
          this.blockUI.stop();
        });
      }
    });
  }

  public async loadEnums() {
    this.enumService.findEnumsByName('termType').subscribe(foundEnums => {
      this.termTypeList = foundEnums;
    });
  }

  public getTermDescription(termType: string): string | undefined {
    return this.termTypeList?.find(item => item.value === termType)?.description;
  }

  public openTermView(term: Term) {
    this.dialog.open(DialogTermComponent, {
        width: '80vw',
        height: 'auto',
        data: {
          termContent: term,
          termDescription: this.getTermDescription(term.type),
          dataSnapshotForTermRendering: this.dataSnapshotForTermRendering
        }
      }
    );
  }

  public redirectToSiteHome(): void {
    this.routerService.navigateByUrl('/');
  }

  public goToPageTop(): void {
    window.scrollTo(0,0);
  }

  public getOfferFirstName(offer: Offer): string {
    const originalName = offer.nome as string;

    if(originalName?.length <= 10) {
      return originalName;
    } else {
      const fullNameArray = originalName?.split(" ");

      return `${fullNameArray?.[0]} ${fullNameArray?.[1]}`;
    }
  }
}
