import { Report } from "../models/report.model";

export const crediblueReports: Report[] = [
    {
        logo: 'estadao',
        title: 'Por que busca por crédito para investimentos aumentou no Brasil?',
        link: 'https://einvestidor.estadao.com.br/comportamento/busca-por-credito-cresceu-brasil-pesquisa/'
    },
    {
        logo: 'monitor-mercantil',
        title: 'Três perguntas: a Crediblue e as operações de home equity',
        link: 'https://monitormercantil.com.br/tres-perguntas-a-crediblue-e-as-operacoes-de-home-equity/'
    },
    {
        logo: 'o-hoje',
        title: 'Home equity é opção para salvar finanças',
        link: 'https://ohoje.com/public/imagens/fotos/amp/2023/02/Jornal-O-Hoje_2023_2_27-1.pdf'
    },
    {
        logo: 'o-popular',
        title: 'Inadimplência cresce 6,8% em Goiânia e preocupa lojistas',
        link: 'https://opopular.com.br/economia/inadimplencia-cresce-6-8-em-goiania-e-preocupa-lojistas-1.3012970'
    },
    {
        logo: 'terra',
        title: 'Enrolado nas dívidas? Esta solução pode ser a salvação dos seus problemas',
        link: 'https://fdr.com.br/2022/05/22/enrolado-nas-dividas-esta-solucao-pode-ser-salvacao-dos-seus-problemas/'
    },
    {
        logo: 'o-hoje',
        title: 'Como as empresas podem se preparar para a recessão?',
        link: 'https://ohoje.com/public/imagens/fotos/amp/2022/10/Jornal-O-Hoje_2022_10_15-e-16.pdf'
    },
    {
        logo: 'mirian',
        title: 'Mães empreendedoras precisam mostrar mais resultados para chegar ao sucesso',
        link: 'https://miriangasparin.com.br/2022/05/maes-empreendedoras-precisam-mostrar-mais-resultados-para-chegar-ao-sucesso/'
    },
    {
        logo: 'logo-estadao-cavalo-azul',
        title: 'Novo Marco das Garantias deve impulsionar home equity no Brasil, mas divide opiniões',
        link: 'https://imoveis.estadao.com.br/noticias/novo-marco-das-garantias-deve-impulsionar-home-equity-no-brasil-mas-divide-opinioes/'
    }
]
