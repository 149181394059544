<div class="dialog">
  <div class="dialog__header">
    <h5>Tudo certo!</h5>
  </div>
  <div class="dialog__content">
    <mat-spinner [diameter]="30" color="#139ECB"></mat-spinner>
    <p>Você está sendo redirecionado para a
      {{ data.byWhatsApp ? "whatsapp da Crediblue" : "Landing Page do Parceiro" }},
      onde fará a conclusão da Simulação de Crédito.
    </p>
  </div>
  <div class="dialog__actions">
    <button class="flat blue-600 max-width animated m-top-16 btn-advance" (click)="redirectToConcludeSimulation()">Ir agora mesmo</button>
  </div>
</div>
