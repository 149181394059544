import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Post } from 'src/app/models/post.model';

@Component({
  selector: 'app-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: ['./post-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PostCardComponent {

  public title$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public category$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public slug$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public image$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public publishedAt$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  
  constructor(
    private router: Router
  ) {}

  @Input()
  public set post(post: Post) {
    this.title$.next(post?.title);
    this.category$.next(post?.category);
    this.slug$.next(post?.slug);
    this.image$.next(post?.cover?.uri);
    this.publishedAt$.next(`${post?.publishedAt}`);
  }

  public openPostVisualization(): void {
    this.router.navigate([`blog/post/${this.slug$.value}`]);
  }

}
