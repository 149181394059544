<section id="bndes-qualificacao" class="bndes-qualification bndes-qualification__header">
  <div class="credi-container bndes-qualification__header__partnership">
    <h6>Uma parceria do BNDES com a Crediblue</h6>
    <img src="../../../assets/imgs/bndes-white.png" alt="CREDIBLUE">
  </div>
</section>


<ng-container *ngIf="!wasCanceledConfirmed; else lamentByCanceled">
  <section *ngIf="offer" class="bndes-qualification bndes-qualification__main bndes-qualification__section">
    <div class="credi-container bndes-qualification__content-and-image">
      <div class="call-text">
        <h2>Olá, {{getOfferFirstName(offer)}}<br>Seja bem-vindo(a)<br>à Crediblue.</h2>
        <p>O Home Equity é uma das principais modalidades de crédito com garantia de imóvel e a Crediblue é referência. <br><strong>Precisamos saber agora se você possui algum imóvel para garantir a operação?</strong></p>

        <mat-button-toggle-group [formControl]="hasWarranty">
          <mat-button-toggle [value]="true">Sim, tenho Imóvel</mat-button-toggle>
          <mat-button-toggle [value]="false">Ainda não tenho Imóvel</mat-button-toggle>
        </mat-button-toggle-group>

        <div class="terms-agree m-top-16" *ngIf="hasWarrantyValue === true && landingPageDataResponse">
          <p><strong>Quase lá...</strong></p>
          <p>Sua proposta junto ao BNDES gerou um identificador que poderá ser solicitado em algum momento de nosso processo:</p>

          <div class="terms-agree__proposal-offer-identifier" cdkCopyToClipboard="{{proposalOfferIdentifier}}"
            (click)="showCopiedContentMessage()" ngbTooltip="Copiar para Área de Transferência">
            <small class="label">Identificador da Proposta:</small>
            <span class="value">{{proposalOfferIdentifier}}</span>
          </div>

          <p>A rede de parceiros da Crediblue atua em todas as regiões do Brasil. A partir de agora você será atendido pelo parceiro <strong>{{getPersonaName(landingPageDataResponse.persona)}}</strong>, que representa a Crediblue na sua região:</p>
          <mat-checkbox class="terms-agree__item" [formControl]="wasAgreededTerms">Declaro e li e concordo com:
            <ng-container *ngFor="let termItem of termToOfferAccept; let i = index">
              <a href="javascript:;" (click)="openTermView(termItem)">{{getTermDescription(termItem.type)}}</a>
              <span *ngIf="i < termToOfferAccept.length -1">,&nbsp;</span>
            </ng-container>.
          </mat-checkbox>

          <div class="actions">
            <button class="flat blue-600 max-width animated m-top-16 btn-advance-request"
              [disabled]="!wasAgreededTermsValue" (click)="continueRequest()">
              CONTINUAR SOLICITAÇÃO
            </button>
            <!--
              <button class="flat blue-600 max-width animated m-top-16 btn-advance-request-wpp"
                [disabled]="!wasAgreededTermsValue" (click)="continueRequest(true)">
                <app-cred-icon size="n6" name="whatsapp"></app-cred-icon> CONCLUIR VIA WHATSAPP
              </button>
            -->
          </div>
        </div>

        <div class="terms-agree m-top-16" *ngIf="hasWarrantyValue === false">
          <p>Infelizmente, neste momento não poderemos dar continuidade à sua solicitação de crédito, pois é necessário fornecer um imóvel como garantia.</p>

          <div class="actions">
            <button class="flat blue-600 max-width animated m-top-16 btn-cancel-request" (click)="openConfirmationCancelDialog()">CANCELAR SOLICITAÇÃO</button>
          </div>
        </div>
        <br>
        <span>*Proposta válida por 48h após aceite na plataforma do BNDES.</span>
      </div>

      <div class="call-image call-image--right">
        <img src="../../../assets/imgs/bndes-qualificacao__secao-1.png" alt="CREDIBLUE">
      </div>
    </div>
  </section>


  <section class="bndes-qualification bndes-qualification__section bndes-qualification__section--purple">
    <div class="credi-container bndes-qualification__content-and-image bndes-qualification__main">
      <div class="call-image call-image--left">
        <img src="../../../assets/imgs/bndes-qualificacao__secao-2.png" alt="CREDIBLUE">
      </div>

      <div class="call-text">
        <h2>Como fazemos acontecer?</h2>
        <ul>
          <li>Avaliação digital de imóveis, inclusive no interior;</li>
          <li>Aceitamos imóveis não averbados;</li>
          <li>Pré-aprovação em até 5 dias úteis;</li>
          <li>Não solicitamos nenhum tipo de pagamento antecipado para aprovação de crédito.</li>
        </ul>
        <div class="actions">
          <a href="javascript:;" (click)="goToPageTop()" class="flat blue-700 max-width animated m-top-16 btn-advance-request">SOLICITAR</a>
        </div>
      </div>
    </div>
  </section>

  <section class="bndes-qualification bndes-qualification__section">
    <div class="credi-container bndes-qualification__content-and-image bndes-qualification__main">
      <div class="call-text">
        <h2>E as vantagens não param por aí:</h2>
        <ul>
          <li>Solução flexível para qualquer finalidade;</li>
          <li>Possibilidade de usar mais de um imóvel como garantia;</li>
          <li>Troca de perfil de dívida (portabilidade);</li>
          <li>Capital de giro;</li>
          <li>Expansão do seu negócio.</li>
        </ul>
        <div class="actions">
          <a href="javascript:;" (click)="goToPageTop()" class="flat purple-400 max-width animated m-top-16 btn-advance-request">SOLICITAR</a>
        </div>
      </div>

      <div class="call-image call-image--right">
        <img src="../../../assets/imgs/bndes-qualificacao__secao-3.png" alt="CREDIBLUE">
      </div>
    </div>
  </section>
</ng-container>
<ng-template #lamentByCanceled>
  <section class="bndes-qualification bndes-qualification__section">
    <div class="credi-container bndes-qualification__content-and-image bndes-qualification__main">
      <div class="call-text">
        <h2>Sentimos muito por isso!</h2>
        <p>
          Lamentamos que neste momento a modalidade de Home Equity não se encaixe no seu perfil.
          Fazemos o convite para você saber mais sobre a Crediblue e sobre a nossa atuação:
        </p>
        <div class="actions">
          <a href="javascript:;" class="flat purple-400 max-width animated m-top-16 btn-advance-request" (click)="redirectToSiteHome()">CONHECER CREDIBLUE</a>
        </div>
      </div>

      <div class="call-image call-image--right">
        <img src="../../../assets/imgs/bndes-qualificacao__secao-3.png" alt="CREDIBLUE">
      </div>
    </div>
  </section>
</ng-template>
