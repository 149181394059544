<div
  class="flex-item f-center f-align-left f-gap-12 f-column blue-600 p-vertical-60-horizontal-54 b-radius-8 pos-relative">
  <app-cred-icon name="email" size="n6"></app-cred-icon>
  <h4 class="white z-index-1">
    Assine nossa newsletter e acompanhe de
    pertinho tudo sobre o<br> mercado imobiliário, de crédito e de tecnologia.
  </h4>
  <p class="default-text white z-index-1">
    Café com Notícias Crediblue: toda quarta-feira,
    sua cápsula semanal de notícias de um jeito fácil e prático.
  </p>
  <form>
    <div class="flex-item ms-flex-column f-gap-16">
      <app-blue-input class="f-30" [control]="nameControl" label="Digite seu nome completo"></app-blue-input>
      <app-blue-input class="f-30" [control]="emailControl" label="Digite o seu e-mail"></app-blue-input>
      <button type="button" (click)="onSubmit()" class="flex-item f-center f-30 flat h-41 f-self-bottom blue-1000 w-100"
              [disabled]="!formValid || !recaptchaValid">Receber Newsletter
      </button>
    </div>
    <div class="flex-item f-center f-justify-left f-gap-12 m-top-16">
      <app-blue-checkbox [control]="termsAcceptControl"></app-blue-checkbox>
      <small class="white">
        Autorizo o envio de conteúdo sobre crédito imobiliário e novos produtos.
        <a (click)="showPrivaciesPoliciesDocument()" class="white politics">Estou de acordo com a Política de
          Privacidade da Crediblue</a>.
      </small>
    </div>
    <div class="flex-item recaptcha">
      <re-captcha class="g-recaptcha" (resolved)="resolved()"  siteKey="6LcChuoZAAAAAFoic9uDW0ZOflO41MWjNPbK4lbf"></re-captcha>
    </div>

  </form>
  <app-cred-icon name="crediblue-bg" size="n10" class="pos-absolute pos-top-right z-index-0"></app-cred-icon>
</div>
