<section class="crediblue-mobile-menu-wrapper">
    <div class="mobile-menu-nav">
      <a class="nav-bar-text" routerLink="/home" routerLinkActive="active-link" (click)="closeMobileMenuState()">A Crediblue</a>
       <section class="mobile-menu-nav-sub">
        <button class="target" #ourSolutionsTarget (click)="activeMenu(ourSolutionsTarget)">
          <span class="nav-bar-text">Nossas Soluções</span>
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" >
              <path d="M6.5 9.5L0.870834 0.5L12.1292 0.5L6.5 9.5Z"/>
              </svg>
          </span>
        </button>
        <div [expandable]="ourSolutionsTarget">
         <div expandable-inner>
           <div class="mobile-sub-menu">
             <a (click)="closeMobileMenuState()" class="nav-bar-text" routerLink="/solutions/credit-type" routerLinkActive="active-link">Tipos de Crédito</a>
             <a (click)="closeMobileMenuState()" class="nav-bar-text" routerLink="/solutions/for-partners" routerLinkActive="active-link" >Para Parceiros</a>
             <a (click)="closeMobileMenuState()" class="nav-bar-text" routerLink="/solutions/simulator" routerLinkActive="active-link" >Simulador</a>
            </div>
         </div>
        </div>
       </section>
       <section class="mobile-menu-nav-sub">
        <button class="target"  #transparencyTarget (click)="activeMenu(transparencyTarget)">
          <span class="nav-bar-text">Transparência</span>
          <span class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" >
              <path d="M6.5 9.5L0.870834 0.5L12.1292 0.5L6.5 9.5Z"/>
              </svg>
          </span>
        </button>
        <div [expandable]="transparencyTarget">
          <div expandable-inner>
            <div class="mobile-sub-menu">
              <a class="nav-bar-text" href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/politica-de-privacidade-de-dados-crediblue.pdf" target="_blank">Política de Privacidade</a>
              <a class="nav-bar-text" href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/Código_de_Ética_e_Conduta_-_Blueway.pdf" target="_blank">Código de Ética</a>
             </div>
          </div>
         </div>
       </section>
      <a class="nav-bar-text" routerLink="/careers" routerLinkActive="active-link" (click)="closeMobileMenuState()">Carreiras</a>
      <a class="nav-bar-text" routerLink="/help" routerLinkActive="active-link" (click)="closeMobileMenuState()">Ajuda</a>
      <a class="nav-bar-text" routerLink="/blog" routerLinkActive="active-link" (click)="closeMobileMenuState()">Blog</a>
      <a class="nav-bar-text" target="_blank" href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/Código_de_Ética_e_Conduta_-_Blueway.pdf">Código de Ética</a>
      <a class="nav-bar-text" target="_blank" href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/politica-de-privacidade-de-dados-crediblue.pdf">Política de Privacidade</a>
      <a class="nav-bar-text" target="_blank" href="https://docs.azo.blue"
      routerLinkActive="active-link">Para Desenvolvedores</a>
      <a class="flat purple-400-s" target="_blank" href="https://crediblue.online">Entrar</a>
      <a class="flat purple-400" routerLink="/be-partner" (click)="closeMobileMenuState()">Seja Parceiro</a>
      </div>
  </section>
