import {Component, Injector, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UtilComponent} from 'src/app/util/util.component';
import {ActiveContact} from 'src/app/models/active-contact.model';

@Component({
  selector: 'app-newsletter-form',
  templateUrl: './newsletter-form.component.html',
  styleUrls: ['./newsletter-form.component.scss']
})
export class NewsletterFormComponent extends UtilComponent implements OnInit{
  public recaptchaValid:boolean = false;
  form!: FormGroup;

  constructor(injector: Injector) {
      super(injector);
  }

  ngOnInit(): void {
    this.createForm();
  }

  public resolved(){
    this.recaptchaValid = true;

  }

  createForm(): void {
    this. form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      termsAccept: [false, Validators.required]
    });
  }

  get nameControl(): FormControl {
    return this.form.controls['name'] as FormControl;
  }

  get emailControl(): FormControl {
    return this.form.controls['email'] as FormControl;
  }

  get termsAcceptControl(): FormControl {
    return this.form.controls['termsAccept'] as FormControl;
  }

  get formValid(): boolean {
    return this.nameControl.errors == null
            && this.emailControl.errors == null
            && this.termsAcceptControl.errors == null;
  }

  onSubmit(): void {
    this.blockUI.start();
    this.newsletterService.sendNewsletterContact(this.form.value as ActiveContact).subscribe({
      next: () => {
        this.notifyService.showSuccess("Cadastro realizado", "Newsletter");
        this.blockUI.stop();
        this.recaptchaValid = false;
      },
      error: (error) => {
        this.notifyService.showError(error.error.message, "Erro");
        this.blockUI.stop();
      }
    })
  }
}
