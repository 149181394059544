import {Component, Injector, OnInit} from '@angular/core';
import {UtilComponent} from "../../../util/util.component";
import {BlogService} from "../../../services/blog.service";
import {Params} from "@angular/router";
import {Post} from "../../../models/post.model";
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss']
})
export class BlogPostComponent extends UtilComponent implements OnInit {

  public post$: BehaviorSubject<Post> = new BehaviorSubject<Post>(null);
  public recommendations$: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>(null);

  constructor(
    private blogService: BlogService,
    injector: Injector
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.loadBlogPost();
  }

  private loadBlogPost(): void {
    this.route.params.subscribe((params: Params) => {
      if (params['slug']) {
        this.blogService.findBySlug(params['slug']).subscribe(
          {
            next: (blogPost) => {
              const post = blogPost?.post;
              const recommendations = blogPost?.recommendations;
              this.formatPostDetails(post);
              recommendations.forEach(this.formatPostDetails);
              this.post$.next(blogPost?.post);
              this.recommendations$.next(recommendations);
              this.setPageTitleAndDescription();
            },
            error: (error) => {
              console.log(error)
            }
          }
        );
      }
    });
  }

  private get post(): Post {
    return this.post$.value;
  }

  private formatPostDetails = (post: Post): void => {
    post.publishedAt = this.formatDate(post?.publishedAt);
    post.updatedAt = this.formatDate(post?.updatedAt);
    post.category = this.getCategoriesDescriptionByName(post?.category);
  }

  private getCategoriesDescriptionByName(postContent: string): string {
    switch (postContent) {
      case 'FINANCES':
        return 'Finanças';
      case 'CREDIT':
        return 'Crédito';
      case 'MARKETING':
        return 'Marketing';
      case 'TECHNOLOGY':
        return 'Tecnologia';
      case 'TIPS':
        return 'Dicas';
      default:
        return '';
    }
  }

  private setPageTitleAndDescription(): void {
    this.customizeMetatags(
      this.post.title + ' | Blog | Crediblue',
      this.post.resume
    );
  }
}
