import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'taxId'
})
export class TaxIdFormatPipe implements PipeTransform {

  transform(taxId: string): string {
    if (!taxId) {
      return '';
    }

    const taxIdValue = taxId.replace(/\D/g, '');

    if(taxIdValue.length == 14) {
      const cnpjParties = taxIdValue.match(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/);
      let cnpjFormatted: string = '';

      if (cnpjParties && cnpjParties.length === 6)
        cnpjFormatted = `${cnpjParties[1]}.${cnpjParties[2]}.${cnpjParties[3]}/${cnpjParties[4]}-${cnpjParties[5]}`;

      return cnpjFormatted;
    } else {
      if(taxIdValue.length == 11) {
        const cpfParties = taxIdValue.match(/^(\d{3})(\d{3})(\d{3})(\d{2})$/);
        let cpfFormatted: string = '';

        if (cpfParties && cpfParties.length === 5)
          cpfFormatted = `${cpfParties[1]}.${cpfParties[2]}.${cpfParties[3]}-${cpfParties[4]}`;

        return cpfFormatted;
      } else {
        return taxId;
      }
    }
  }
}
