<section class="credi-container m-top-96">
  <ng-container *ngIf="!(isBlogEmpty$ | async)">
    <div class="w-100 pos-relative" *ngIf="!(isSearchOngoing$ | async)">
      <div class="blog-banner">
        <h2>Home Equity Crediblue </h2>
        <h5>O maior portal de empréstimo com garantia de imóvel.</h5>
      </div>
      <div class="pos-absolute pos-bottom-bellow pos-horizontal-center">
        <app-newsletter-form-small></app-newsletter-form-small>
      </div>
    </div>
    <div class="blog-list-wrapper"
          infinite-scroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="1000"
          (scrolled)="onScroll()"
    >
      <div class="blog-list-container">
        <div class="blog-list-highlighteds" *ngIf="!(isSearchOngoing$ | async)">
          <h3>Destaques</h3>
          <div>
            <app-post-card *ngFor="let highlightedPost of highlightedPosts$ | async" [post]="highlightedPost"></app-post-card>
          </div>
        </div>
        <div class="blog-list-regulars">
          <h3 *ngIf="isSearchOngoing$ | async">Resultados da Pesquisa:</h3>
          <div class="blog-list-regulars__item" *ngFor="let post of regularPosts$ | async">
            <img [src]="post.cover.uri" [alt]="post?.title"/>
            <div class="blog-list-regulars__item__details">
              <span class="topic">{{post?.category}}</span>
              <h2 (click)="openPostVisualization(post)">{{post?.title | truncate}}</h2>
              <p>{{post?.resume | truncate: 150}}</p>
              <span class="info">por: {{post?.author}} | {{post?.publishedAt}}</span>
            </div>
          </div>
          <div class="blog-list-regular__loading" *ngIf="(regularPosts$ | async)?.length == 0">
            <span></span>
          </div>
        </div>
      </div>
      <div class="blog-list-actions">
        <div class="blog-list-actions__filter">
          <app-blue-search-input
            *ngIf="filter$ | async as filter"
            [control]="filter"
            width="100%"
            (event)="searchPosts()"
          ></app-blue-search-input>
          <div class="blog-list-actions__filter__categories">
            <p>
              Categorias
              <a (click)="onClickOnTopic()">
                (Limpar Filtros)
              </a>
            </p>
            <a (click)="onClickOnTopic('FINANCES')">Finanças</a>
            <a (click)="onClickOnTopic('CREDIT')">Crédito</a>
            <a (click)="onClickOnTopic('MARKETING')">Marketing</a>
            <a (click)="onClickOnTopic('TECHNOLOGY')">Tecnologia</a>
            <a (click)="onClickOnTopic('TIPS')">Dicas</a>
          </div>
        </div>
        <div class="blog-list-actions__cta">
          <a href="https://capital.crediblue.com.br/mais-negocios" target="_blank" class="banner">
            <img src="../../../../assets//imgs/Banner Lateral Blog Crediblue - Produtos.png" alt="Oportunidades de negócios Crediblue">
          </a>
        </div>
      </div>
    </div>
  </ng-container>
  <div class="credi-center f-column f-gap-32 p-vertical-24" *ngIf="isBlogEmpty$ | async">
    <h6>
      Ainda não temos nenhum post cadastrado!
    </h6>
    <app-newsletter-form></app-newsletter-form>
  </div>
</section>
