<div class="overflow-hidden card-container flex-item f-column">
  <div class="w-400 h-80 pos-relative">
    <div class="w-100 flex-item pos-absolute" [@scrollLeft]="'position'.concat(position)">

      <div class="w-25" *ngFor="let content of contents">
        <app-home-testimony-card [content]="content"></app-home-testimony-card>
      </div>

    </div>
  </div>
  <div class="flex-item w-33 m-left-700 responsive-width-buttons">
    <button *ngIf="position === '1'">
    </button>
    <button (click)="onClickChange(-1)" *ngIf="position !== '1'">
      <app-cred-icon name="arrow-left" size="n8"></app-cred-icon>
    </button>
    <div class="flex-item">
      <ng-container *ngFor="let number of [1, 2, 3, 4]">
        <button [class]="isActive(number)" (click)="onClickDotChange(number)"></button>
      </ng-container>
    </div>
    <button (click)="onClickChange(1)" *ngIf="position !== '4'">
      <app-cred-icon name="arrow-right" size="n8"></app-cred-icon>
    </button>
    <button *ngIf="position === '4'">
    </button>
  </div>
</div>
