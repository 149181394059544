import {Component, Input} from '@angular/core';
import {TextImage} from 'src/app/models/contents.model';

@Component({
  selector: 'app-strategy-card',
  templateUrl: './strategy-card.component.html',
  styleUrls: ['./strategy-card.component.scss']
})
export class StrategyCardComponent {

  @Input()
  strategy!: TextImage;

}
