import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

type ComponentClass = 'block-ui' | 'spinner';

@Component({
  selector: 'credi-block-ui',
  templateUrl: './credi-block-ui.component.html',
  styleUrls: ['./credi-block-ui.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CrediBlockUiComponent implements OnInit {
  @Input()
  public isActive: boolean;
  
  @Input()
  public class: ComponentClass = 'block-ui';
  
  public readonly filenames: string[] = [
    'azo-logo',
    'azo-logo-white-vector',
    'azo-logo-blue-vector'
  ];

  constructor() {}
  
  ngOnInit(): void {}

  public get animationClass(): string {
    return this.class.concat(!this.isActive ? ' fade-out' : '');
  }

  public get display(): string {
    return this.isActive ? 'flex' : 'none';
  }

  public getFilePath(filename: string): string {
    return `../../../assets/imgs/azo-load-svg/${filename}.svg`;
  }

}
