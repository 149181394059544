import {Component, OnInit} from '@angular/core';
import {environment} from "../../../../environments/environment";
import {UtilComponent} from "../../../util/util.component";

@Component({
  selector: 'app-embedded-simulator',
  templateUrl: './embedded-simulator.component.html',
  styleUrls: ['./embedded-simulator.component.css']
})
export class EmbeddedSimulatorComponent extends UtilComponent implements OnInit {

  protected readonly environment = environment;

  protected readonly partnerURL: string = `${environment.partnerUrl}/#/embedded-simulator`;

  ngOnInit(): void {
    this.setPageTitleAndDescription();
    this.handleIframeLoading();
  }

  setPageTitleAndDescription() {
    this.customizeMetatags(
      'Simulador | Crediblue',
      'Simule operações de crédito.'
    );
  }

  public handleIframeLoading() {
    const iframe = document.getElementById('iframe') as HTMLIFrameElement;
    
    this.blockUI.start();

    iframe.onload = () => {
      this.blockUI.stop();
    };

    iframe.onerror = () => {
      this.blockUI.stop();
    };
  }
}
