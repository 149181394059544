<main class="p-relative planning bg-image">
  <div class="credi-container">
    <h1 class="purple-400">Mais solução por metro quadrado</h1>
    <h5 class="default-text blue-1000 max-width-p">
      <br>Com a Crediblue, você aumenta o seu portfólio e facilita a vida dos seus clientes.
      O crédito com garantia de imóvel é a solução para quem deseja fugir de altos juros.<br><br>

      E o melhor: tudo sem burocracia. Conheça as soluções para seus clientes.
    </h5>
  </div>
  <app-cred-icon name="crediblue-bg" size="n7" class="pos-absolute pos-top-right credi-simbol-right"></app-cred-icon>
</main>

<section>
  <div class="credi-container credi-center tabs-responsive-alignment">
    <button [class]="currentType == 'he' ? 'radio active' : 'radio'" (click)="changeCreditType('he')">HOME EQUITY
    </button>
    <button [class]="currentType == 'fi' ? 'radio active' : 'radio'" (click)="changeCreditType('fi')">FINANCIAMENTO
      IMOBILIÁRIO
    </button>
    <button [class]="currentType == 'cc' ? 'radio active' : 'radio'" (click)="changeCreditType('cc')">CRÉDITO PARA
      CONSTRUÇÃO
    </button>
  </div>
</section>

<section class="credi-container credi-space-between f-align-top section-height">
  <div class="flex-item f-40 f-column responsive-top-alignment">
    <h2 class="purple-400">{{ currentTypeData.mainContent.title }}</h2>
    <h5 class="default-text">
      {{ currentTypeData.mainContent.text }}<br><br><br><br>
    </h5>
    <a class="flat blue-600 max-width animated" routerLink="/be-partner">QUERO SABER MAIS</a>
  </div>
  <div [class]="getClass(true)"></div>
</section>

<section class="grey-200">
  <div class="credi-container credi-center f-gap-48 f-align-top tabs-responsive-alignment flex-responsive-alignment">
    <div class="flex-item f-50 f-column">
      <h2 class="purple-400">Visão inovadora para seu negócio</h2>
      <h5 class="blue-1000">Ofereça opções estratégicas para os seus clientes:</h5>
    </div>
    <div class="flex-item f-50 f-column f-gap-12 card-width">
      <ng-container *ngFor="let strategy of currentTypeData.strategies">
        <app-strategy-card [strategy]="strategy"></app-strategy-card>
      </ng-container>
    </div>
  </div>
</section>

<section class="credi-container flex-item f-res-center">
  <div class="flex-responsive-alignment-button">
    <div class="flex-item f-column m-top-50 m-bottom-50 tabs-responsive-alignment">
      <h2 class="purple-400">Vantagens para seu cliente</h2>
      <h5 class="blue-1000" *ngIf="currentType == 'he'">Você à frente. Ofereça crédito com juros atrativos para seu
        cliente PF e PJ</h5>
    </div>
    <div class="flex-item f-gap-24 flex-responsive-alignment-advantage">
      <div *ngFor="let advantage of currentTypeData.advantages">
        <app-advantage-card [advantage]="advantage" color="blue"></app-advantage-card>
      </div>
    </div>
    <a class="flat blue-600 m-top-64 m-bottom-84 max-width animated" routerLink="/be-partner">QUERO SER PARCEIRO</a>
  </div>
</section>

<section class="blue-700">
  <div class="flex-item tabs-responsive-alignment-section">
    <div class="credi-container flex-item f-center text-style">
      <p class="default-text white align-left">
        {{ currentTypeData.disclaimer.text }}<br><br>
        {{currentTypeData.disclaimer.author}}
      </p>
    </div>
    <div class="tab-photo-img f-75 photo-style" [class]="getClass(false)"></div>
  </div>
</section>

<section class="credi-container credi-center m-top-36 p-vertical-64-horizontal-0">
  <app-newsletter-form></app-newsletter-form>
</section>
