<footer>
  <div class="credi-container">
    <section class="grid-container grid-4-c f-gap-48">
      <div class="small-item flex-item f-res-center text-align-center f-column f-justify-left">
        <app-cred-icon name="crediblue-white-logo" size="n1"></app-cred-icon>
        <h6 style="margin-top: 38px;">ENDEREÇO</h6>
        <small>
          Rua 72, 325
          <br>
          Ed. Trend Office, 6º Andar
          <br>
          Jardim Goiás, Goiânia - GO, 74805-480
        </small>
      </div>
      <nav class="small-item flex-item f-center f-res-center text-align-center f-justify-start">
        <ul style="padding: 0;">
          <li><a routerLink="/home" class="default white">A Crediblue</a></li>
          <li><a routerLink="/solutions/credit-type" class="default white">Tipos de Empréstimos</a></li>
          <li><a routerLink="/solutions/for-partners" class="default white">Parceiro</a></li>
          <li><a routerLink="/blog" class="default white">Blog</a></li>
          <li><a routerLink="/help" class="default white">Ajuda</a></li>
          <li><a routerLink="/careers" class="default white">Carreiras</a></li>
          <li><a href="https://docs.azo.blue" class="default white">Para Desenvolvedores</a></li>
        </ul>
      </nav>
      <div class="small-item flex-item f-res-center f-column text-align-center">
        <h6>Fale com a gente</h6>
        <small>
          <!--
          TELEFONE<br>
          <strong><a href="tel:556234147645" class="call-click-anchor">(62) 3414-7645</a></strong>-->
          <br><br>Segunda a sexta, das 8h às 18h.<br><br>

          <strong><a href="mailto:comercial@crediblue.com.br"
                     class="call-click-anchor">comercial@crediblue.com.br</a></strong><br>
          Atendimento a clientes e dúvidas gerais.
        </small><br><br>
        <a class="flat blue-600 max-width animated" routerLink="/be-partner">SEJA PARCEIRO</a>
      </div>
      <div class="small-item flex-item f-res-center f-center f-justify-start f-column">
        <h6>Siga Nossas Redes</h6>
        <div style="display: flex; gap: 4px">
          <a class="rounded blue-700" target="_blank" href="https://www.youtube.com/@crediblue5861">
            <app-cred-icon name="youtube" size="n5">
            </app-cred-icon>
          </a>
          <a class="rounded blue-700" target="_blank" href="https://www.instagram.com/crediblue/">
            <app-cred-icon name="instagram" size="n5">
            </app-cred-icon>
          </a>
          <a class="rounded blue-700" target="_blank" href="https://www.linkedin.com/company/crediblue/">
            <app-cred-icon name="linkedin" size="n5">
            </app-cred-icon>
          </a>
        </div>
      </div>
    </section>
    <span class="divider"></span>
    <article>
      <p class="disclaimer">
        A Crediblue não realiza qualquer atividade privativa de instituições financeiras.
        Essas operações são realizadas através de parceiros autorizados pelo BACEN.
        Somos uma correspondente bancária da instituição financeira QI SCD, QI SOCIEDADE DE CRÉDITO DIRETO S.A,
        inscrito no CNPJ sob o Nº32.402.502/0001-35,
        com sede na Avenida Brigadeiro Faria lima, 2.391, 1° andar, CJ 12 nos termos da Resolução no 4.935,
        de 29 de julho de 2021. A Crediblue, por sua vez, possui sede na Avenida Deputado Jamel Cecílio,
        Jardim Goiás, 2496, Goiânia - GO, registrada sob o CNPJ 36.750.257/0001-08.<br><br>

        Informações complementares referentes ao Empréstimo com Garantia de Imóvel:
        mínimo de 36 meses e máximo de 120 meses.
        “Exemplo – Empréstimo de R$ 200.000,00 para pagar em 10 anos (120 meses)
        com LTV de 47,19% (sendo R$ 200.000,00 + despesas acessórias,
        para um apartamento avaliado em R$ 500.000,00) – Prestação inicial de R$ 3.728,68
        com uma taxa de juros de 1,14% ao mês + IPCA, Sistema de Amortização Tabela Price.
        CET de 19,59% ao ano.” * O valor nominal, compreendendo principal e despesas acessórias,
        poderá ser de até 60% (sessenta por cento) do valor de avaliação do imóvel (home equity).
        Conforme artigo 6º, inciso II, da resolução de nº 4.676 de 31 de julho de 2018 do BACEN.
      </p>
    </article>
    <section class="flex-item f-justify-space-between">
      <div class="tech-info-container">
        <p class="rights fix-on-bottom">
          ©2022-2023 Crediblue. Todos os direitos reservados.
          Conheça nossa Política de Privacidade,
          Termo de Uso e Autorização para Consulta SCR.
        </p>
      </div>
      <div class="tech-info-container">
        <a class="text white"
         href="https://crediblue-pro.s3.us-east-1.amazonaws.com/crediblue/crediblue-site/C%C3%B3digo_de_%C3%89tica_e_Conduta_-_Blueway.pdf"
        target="_blank" rel="noopener noreferrer">Código de Ética</a>
        <a class="text white"
           href="mailto:{{supportEmailAddress}}?subject={{supportEmailSubject}}&body={{supportEmailBody}}">Suporte</a>
        <span class="vertical-divider"></span>
        <app-cred-icon name="bluetech-logo" size="n2"></app-cred-icon>
      </div>
    </section>
  </div>
</footer>
