import { Component} from '@angular/core';
import { CrediblueMobileMenuService } from '../crediblue-mobile-menu/crediblue-mobile-menu.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(private crediblueMobileMenuService:CrediblueMobileMenuService) {}

  public changeMobileMenuState() {
    this.crediblueMobileMenuService.onToggle.next(false)
  }


}
