import { Component, Inject, Injector, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogState } from '@angular/material/dialog';
import { UtilComponent } from 'src/app/util/util.component';

@Component({
  selector: 'app-dialog-redirecting-to-simulator',
  templateUrl: './dialog-redirecting-to-simulator.component.html',
  styleUrls: ['./dialog-redirecting-to-simulator.component.scss']
})
export class DialogdialogRedirectingToSimulatorComponent extends UtilComponent implements OnInit {

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<DialogdialogRedirectingToSimulatorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { urlToRedirect: string, byWhatsApp: boolean },
  ) {
    super(injector);
  }

  ngOnInit() {
    setTimeout(() => {
      if(this.dialogRef.getState() != MatDialogState.CLOSED)
        this.redirectToConcludeSimulation();
    }, 6000);
  }

  public redirectToConcludeSimulation(): void {
    window.open(this.data.urlToRedirect) || window.location.assign(this.data.urlToRedirect)
    this.dialogRef.close();
  }
}
