import {Component, OnInit} from '@angular/core';
import {Term} from "../../../models/term.model";
import {SimpleModalComponent} from "ngx-simple-modal";
import {OportunityService} from "../../../services/oportunity.service";

export interface ModalTermModel {
  termContent: Term;
  termDescription: string;
  dataSnapshotForTermRendering: string;
}

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss']
})
export class GenericModalComponent extends SimpleModalComponent<ModalTermModel, boolean> implements OnInit {
  termDescription: string = "";
  termContent: Term;
  dataSnapshotForTermRendering: string;


  constructor(public oportunityService: OportunityService) {
    super();
  }

  async ngOnInit() {
    this.loadCurrentTerm();
  }

  public loadCurrentTerm() {
    try {
      this.termContent.markup = this.oportunityService.buildTermTemplate(this.termContent, this.dataSnapshotForTermRendering);
    } catch (err: any) {
    }
  }
}
