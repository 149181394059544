<section class="m-top-150">
  <div class="credi-container credi-center align-buttons">
    <ng-container *ngFor="let data of filteredTabData">
      <button [class]="currentTopic ==  data.topic ? 'radio active' : 'radio'"
              (click)="changeCurrentData(data.topic)">
        {{ data.title }}
      </button>
    </ng-container>
  </div>
</section>
<section class="credi-container">
  <div class="flex-item f-center f-justify-space-between m-vertical-120 f-res-center">
    <h1 class="purple-400">Perguntas mais frequentes</h1>
    <app-blue-search-input [control]="filter" (event)="onChangeValue()"></app-blue-search-input>
  </div>
  <ng-container *ngFor="let topic of currentTopicData.questions">
    <crediblue-help-panel [topic]="topic"></crediblue-help-panel>
  </ng-container>
</section>
<section class="flex-item help-button">
  <a class="flat purple-400 m-left-12"
     href="mailto:{{supportEmailAddress}}?subject={{supportEmailSubject}}&body={{supportEmailBody}}">
    TIRAR DÚVIDAS
  </a>
</section>
<section class="credi-container credi-center p-vertical-108">
  <app-newsletter-form></app-newsletter-form>
</section>
