<div class="credi-center blue-600 b-radius-8 form-wrapper">
  <p class="white">
    Entre para nossa lista e acompanhe de pertinho <br> 
    tudo sobre o mercado de tech-crédito-imobiliário!  
  </p>
  <form *ngIf="form$ | async as form" [formGroup]="form" class="flex-item">
    <app-blue-input label="Seu e-mail" [control]="emailControl"></app-blue-input>
    <button 
      class="flex-item f-center flat h-41 f-self-bottom blue-1000 w-100" 
      type="button" 
      (click)="onSubmit()"
    >
       Receber Newsletter
    </button>
  </form>
</div>
