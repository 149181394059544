export class PaginatorModel {
  page: number;
  per_page: number;
  pre_page: number | null | undefined;
  next_page: number | null | undefined;
  total: number;
  total_pages: number;
  data: any[];
  last?: boolean;

  constructor(
    page: number = 1,
    per_page: number = 5,
    pre_page?: number | null,
    next_page?: number | null,
    total: number = 0,
    total_pages: number = null as any,
    data: any[] = null as any,
    last?: boolean | undefined
  ) {
    this.page = page;
    this.per_page = per_page;
    this.pre_page = pre_page;
    this.next_page = next_page;
    this.total = total;
    this.total_pages = total_pages;
    this.data = data;
    this.last = last;
  }
}
