import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cred-icon',
  template: `
  <svg [class]="size">
    <use [attr.xlink:href]="this.urlIcon"></use>
  </svg>
`,
  styleUrls: ['./cred-icon.component.css']
})
export class CredIconComponent {

  urlIcon!: string;

  @Input()
  size!: string;

  @Input()
  set name(slug: string) {
    this.urlIcon = `../../../assets/imgs/crediblue-imgs.svg#${slug}`;
  }

}
