<div class="dialog">
  <div class="dialog__header">
    <h5>Cancelamento</h5>
  </div>
  <div class="dialog__content">
    <p>Após clicar em confirmar, sua proposta criada com a Crediblue será cancelada junto ao BNDES.</p>
    <p><strong>Deseja CONFIRMAR o cancelamento de sua Proposta?</strong></p>
  </div>
  <div class="dialog__actions">
    <button class="flat blue-600 btn-confirm-cancel" (click)="cancelCreditProposal()">CONFIRMAR</button>
    <button class="flat btn-exit" cdkFocusInitial (click)="closeDialog()">Não tenho certeza</button>
  </div>
</div>
