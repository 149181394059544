import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-blue-search-input',
  templateUrl: './blue-search-input.component.html',
  styleUrls: ['./blue-search-input.component.scss']
})
export class BlueSearchInputComponent {

  @Input()
  public control!: FormControl;

  @Input()
  public width: string;

  @Output()
  public event = new EventEmitter<any>();

  @Output()
  public isSearchPage = new EventEmitter<any>();

  public changeText(): void {
    this.event.emit();
  }

  public isSearchPageOrInitPage(): void {
    this.isSearchPage.emit();
  }

}
