import {Component, Inject, Injector, OnInit, PLATFORM_ID} from '@angular/core';
import {UtilComponent} from "../../../util/util.component";
import {BlogService} from "../../../services/blog.service";
import {FormControl} from "@angular/forms";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ViewportScroller, isPlatformBrowser} from "@angular/common";
import {Post} from "../../../models/post.model";
import { BehaviorSubject } from 'rxjs';
import { PaginatorModel } from 'src/app/models/paginator.model';

@Component({
  selector: 'app-blog-list',
  animations: [
    trigger('scrollLeft', [
      state('position0', style({
        left: 0
      })),
      state('position1', style({
        left: '-100%'
      })),
      state('position2', style({
        left: '-200%'
      })),
      state('position3', style({
        left: '-300%'
      })),
      state('position4', style({
        left: '-400%'
      })),
      transition('* => *', [
        animate('0.3s')
      ]),
    ])
  ],
  templateUrl: './blog-list.component.html',
  styleUrls: ['./blog-list.component.scss']
})
export class BlogListComponent extends UtilComponent implements OnInit {

  public position: number = 0;

  public highlightedPosts$: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>([]);
  public regularPosts$: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>([]);
  public filteredPosts$: BehaviorSubject<Post[]> = new BehaviorSubject<Post[]>([]);
  
  public filter$: BehaviorSubject<FormControl> = new BehaviorSubject(null);

  public isBlogEmpty$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isSearchOngoing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private currentPage: number = 0;

  private topic$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private numberOfMostViewedPosts$: BehaviorSubject<number> = new BehaviorSubject<number>(null);

  private readonly PAGE_TOP_INDEX: [number, number] = [0, 0];
  private readonly SLIDER_INTERVAL: number = 5000;
  private readonly INITIAL_PAGE: number = 0;
  private readonly EMPTY_BLOG: number = 0;
  private readonly PAGE_SIZE: number = 10;

  constructor(
    private blogService: BlogService,
    private viewport: ViewportScroller,
    @Inject(PLATFORM_ID) private platformId: any,
    injector: Injector
  ) {
    super(injector);
    this.createFilterControl();
  }

  public ngOnInit(): void {
    this.setPageTitleAndDescription();

    this.loadHighlightedPosts();

    this.performTimerSliderTransition();
  }

  private get numberOfMostViewedPosts(): number {
    return this.numberOfMostViewedPosts$.value;
  }

  private get topic(): string {
    return this.topic$.value;
  }

  private get filter(): FormControl {
    return this.filter$.value;
  }

  public onClickOnTopic(topic: string = null): void {
    this.topic$.next(topic);
    this.filter.reset();
    this.searchPosts();
  }

  public openPostVisualization(post: Post): void {
    post && this.routerService.navigate([`blog/post/${post?.slug}`]);
  }

  public searchPosts(): void {
    const filterValue = this.filter.value;
    const topic = this.topic;
    this.viewport.scrollToPosition(this.PAGE_TOP_INDEX);
    this.isSearchOngoing$.next(this.anyStringValuesPresent(filterValue, topic));
    this.loadRegularPosts();
  }

  public onScroll(): void {
    this.currentPage++;
    this.loadRegularPosts(this.currentPage);
  }

  private loadHighlightedPosts(): void {
    this.blogService.findHighlightedPosts()
      .subscribe(
        {
          next: (paginator: PaginatorModel) => {
            const highlightedPosts: Post[] = paginator?.data;
            highlightedPosts?.forEach(this.formatPostDetails);
            this.highlightedPosts$.next(highlightedPosts);
            this.loadRegularPosts();
          },
          error: (error) => {
            console.log(error);
          }
        }
      );
  }

  private loadRegularPosts(page: number = 0): void {
    const filterValue = this.filter.value;
    const topic = this.isStringValuePresent(filterValue) ? null : this.topic;
    this.blogService.findAll(
      page, this.PAGE_SIZE, filterValue, topic
    ).subscribe(
        {
          next: (paginator) => {
            const data: Post[] = paginator?.data;
            data?.forEach(this.formatPostDetails);
            this.handleRegularPosts(page, data);
          },
          error: (error) => {
            console.log(error)
          }
        }
      );
  }

  private handleRegularPosts(page: number, data: Post[]): void {
    if (page === this.INITIAL_PAGE) {
      this.currentPage = this.INITIAL_PAGE;
      this.regularPosts$.next(data);
    }
    else {
      const regularPosts: Post[] = this.regularPosts$.value;
      this.regularPosts$.next([...regularPosts, ...data]);
    }
    this.isBlogEmpty$.next(this.regularPosts$.value.length === this.EMPTY_BLOG);
  }

  private performTimerSliderTransition(): void {
    isPlatformBrowser(this.platformId) && setInterval(this.scrollOnTime, this.SLIDER_INTERVAL);
  }

  private scrollOnTime = (): void => {
    if (this.numberOfMostViewedPosts > 1) {
      if (this.position <= this.numberOfMostViewedPosts) {
        this.changePosition(1)
      }
      else if (this.position === this.numberOfMostViewedPosts) {
        this.changePosition(-this.numberOfMostViewedPosts - 1)
      }
    }
  }

  private changePosition(number: number): void {
    let actual = this.position;
    actual += number;
    this.position = actual;
  }

  private formatPostDetails = (post: Post): void => {
    post.publishedAt = this.formatDate(post?.publishedAt);
    post.category = this.getCategoriesDescriptionByName(post?.category);
  }

  public getCategoriesDescriptionByName(postContent: string): string {
    switch (postContent) {
      case 'FINANCES':
        return 'Finanças';
      case 'CREDIT':
        return 'Crédito';
      case 'MARKETING':
        return 'Marketing';
      case 'TECHNOLOGY':
        return 'Tecnologia';
      case 'TIPS':
        return 'Dicas';
      default:
        return '';
    }
  }

  private createFilterControl(): void {
    const control = new FormControl('');
    this.filter$.next(control);
  }

  private setPageTitleAndDescription(): void {
    this.customizeMetatags(
      'Blog | Crediblue',
      'Veja as últimas notícias sobre Finanças, Crédito, e muito mais.'
    );
  }
}
