import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {map, Observable} from "rxjs";
import {PaginatorModel} from "../models/paginator.model";
import {BlogPost} from "../models/blog-post.model";

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private apiUrl: string;

  private readonly BLOG_PATH: string = 'blog/posts';

  private readonly DEFAULT_PAGE: number = 0;
  private readonly DEFAULT_MOST_VIEWED_AMOUNT: number = 6;
  private readonly DEFAULT_HIGHLIGHTEDS_AMOUNT: number = 2;
  private readonly DEFAULT_PUBLISH_POST: string = 'PUBLISHED';

  constructor(
    private http: HttpClient
  ) {
    this.apiUrl = `${environment.apiUrl}/${this.BLOG_PATH}`;
  }

  public findHighlightedPosts(): Observable<PaginatorModel> {
    return this.findAll(
      this.DEFAULT_PAGE, 
      this.DEFAULT_HIGHLIGHTEDS_AMOUNT, 
      null, 
      null,
      this.DEFAULT_PUBLISH_POST, 
      true
      );
  }

  public findMostViewedPosts(): Observable<PaginatorModel> {
    return this.findAll(
      this.DEFAULT_PAGE, 
      this.DEFAULT_MOST_VIEWED_AMOUNT, 
      null, 
      null,
      this.DEFAULT_PUBLISH_POST,      
      false, 
      'views,desc'
    );
  }

  public findRegularPosts(page: number = 0, size: number = 10): Observable<PaginatorModel> {
    return this.findAll(page, size);
  }

  public findAll(
    page: number = 0,
    size: number = 4,
    title: string = '',
    category: string = '',
    status: string = 'PUBLISHED',
    highlighted: boolean = false,
    sortBy: string = 'publishedAt,desc'
  ): Observable<PaginatorModel> {

    const requestURL =
      `${this.apiUrl}` +
      `?title=${title ?? ''}` +
      `&category=${category ?? ''}` +
      `&status=${status}` +
      `&page=${page}` +
      `&size=${size}` +
      `&highlighted=${highlighted}` +
      `&sort=${sortBy}`;

    return this.http.get(requestURL)
      .pipe(
        map((value: any) => {
          return this.paginator(
            value.content,
            page,
            size,
            value.totalPages,
            value.totalElements
          );
        })
      );
  }

  public findBySlug(slug: string): Observable<BlogPost> {
    return this.http.get<BlogPost>(`${environment.apiUrl}/blog/posts/${slug}`);
  }

  private paginator(
    items: any,
    page: number,
    size: number,
    totalPages: number,
    totalItens: number,
    last?: boolean
  ): PaginatorModel {
    const page_tmp = page || 1;
    const size_tmp = size || 10;

    return new PaginatorModel(
      page_tmp,
      size_tmp,
      page_tmp - 1 ? page_tmp - 1 : null,
      totalPages > page_tmp ? page_tmp + 1 : null,
      totalItens,
      totalPages,
      items,
      last
    );
  }

}
