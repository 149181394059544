import { Injectable } from '@angular/core';
import { ActiveContact } from '../models/active-contact.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NewsletterService {

  private activePath: string = 'active/newsletter';

  constructor(private http: HttpClient) { }

  get apiFullPath(): string {
    return `${environment.apiUrl}/${this.activePath}`;
  }

  sendNewsletterContact(contact: ActiveContact): Observable<any> {
    return this.http.post(this.apiFullPath, contact);
  }
}
