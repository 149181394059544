import { CreditTypeTemplate } from "../models/credit-types.model";

export const CreditTypeTemplateData: CreditTypeTemplate[] = [
    {
        creditType: 'he',
        mainContent: {
            title: 'Home Equity',
            text: 'Solução de crédito flexível e inteligente para qualquer finalidade. Conheça a modalidade de crédito que mais impulsiona o empreendedorismo no Brasil. ',
            image: 'home-equity'
        },
        strategies: [
            {
                text: 'Capital de giro',
                image: 'coin'
            },
            {
                text: 'Ampliação de negócio',
                image: 'money'
            },
            {
                text: 'Troca de perfil de dívida',
                image: 'bullseye'
            },
            {
                text: 'Compra de um imóvel',
                image: 'acquire'
            },
            {
                text: 'Realização de sonhos',
                image: 'rocket'
            },
        ],
        advantages: [
            {
                text: 'Empréstimo entre R$ 50 mil e R$5 milhões',
                image: 'check'
            },
            {
                text: 'Possibilidade de usar mais de um imóvel como garantia',
                image: 'use'
            },
            {
                text: 'Taxas a partir de 1,08 + IPCA a.m.',
                image: 'calculator'
            },
            {
                text: 'Pagamento em até 120 meses',
                image: 'work'
            },
            {
                text: 'Flexibilidade para receber até 60% do valor de avaliação da garantia.',
                image: 'talk'
            },
        ],
        disclaimer: {
            text: '“O home equity ou Crédito com Garantia de Imóvel (CGI), como é mais conhecido, vem ganhando força no Brasil há alguns anos. Com este produto de crédito, os clientes têm a chance de dar uma arrumada na vida financeira, respirar e seguir em frente sem necessariamente precisar ir ao banco tradicional dele. A iFinc vem usando a plataforma da Crediblue há pouco tempo e já enxergamos uma ótima e promissora parceria. A combinação de parceria comercial, excelentes gerentes comerciais, tecnologia de ponta e velocidade em retornos vem ajudando a iFinc a entregar com mais agilidade e qualidade os retornos das propostas de home equity. Com isso, todos ganham: a iFinc, a Crediblue e, principalmente, o cliente final na ponta”.',
            author: '-iFinc',
            image: 'house'
        }
    },
    {
        creditType: 'fi',
        mainContent: {
            title: 'Financiamento imobiliário',
            text: 'O imóvel perfeito para o seu cliente, residencial ou comercial. Ofereça crédito imobiliário sem burocracia e com atendimento personalizado.',
            image: 'fi'
        },
        strategies: [
            {
                text: 'Avaliação de crédito flexível',
                image: 'credit'
            },
            {
                text: 'Aquisição de imóvel novo, na planta ou usado, residencial ou comercial',
                image: 'property'
            },
            {
                text: 'Aquisição de imóvel de alto padrão',
                image: 'trophy'
            },
            {
                text: 'Sem burocracia, demora no processo e longe dos altos juros',
                image: 'tapes'
            },
        ],
        advantages: [
            {
                text: 'Crédito disponívelpara cliente PF e PJ',
                image: 'persons'
            },
            {
                text: 'Empréstimo entre R$50 mil e R$5 milhões',
                image: 'values'
            },
            {
                text: 'Taxas a partir de 1,08 + IPCA a.m.',
                image: 'calculator'
            },
            {
                text: 'Até 80% do valor de avaliação da garantia',
                image: 'pork'
            },
            {
                text: 'Pagamento em até 120 meses',
                image: 'installments'
            },
            {
                text: 'Possibilidade de inserir imóveis não averbados como garantia',
                image: 'flex'
            },
            {
                text: 'Primeiro desembolso só na primeira parcela',
                image: 'sign'
            },
        ],
        disclaimer: {
            text: '"Nós, da Agile Consultoria, somos uma empresa parceira e trabalhamos com o produto de financiamento imobiliário. A Crediblue se destaca em importantes diferenciais na facilitação do crédito, de forma muita rápida em seu segmento. Com uma plataforma de simulação intuitiva, muito fácil de utilizar, além de toda praticidade, ela permite um fluxo de crédito com mais acesso para a nossa atuação em defesa do cliente. Isso por permitir, através da garantia de um imóvel, o acesso ao crédito mais barato para ser utilizado como quiser, seja realizar um sonho ou reorganizar a vida financeira".',
            author: '-Agile Consultoria',
            image: 'happy'
        }
    },
    {
        creditType: 'cc',
        mainContent: {
            title: 'Crédito para construção',
            text: 'Solução de crédito perfeita para financiar mão de obra profissional, adquirir materiais para a construção ou finalizar uma obra parada. Se esse é o caso do seu cliente, o Crédito para Construção é ideal para te ajudar a fechar a operação.',
            image: 'cc'
        },
        strategies: [
            {
                text: 'Valorize o imóvel do seu cliente ao possibilitar a conclusão da obra',
                image: 'open'
            },
            {
                text: 'Imóvel reformado pode aumentar (e muito) seu valor de venda',
                image: 'renew'
            },
            {
                text: 'Realocação de investimento',
                image: 'target'
            },
            {
                text: 'Possibilidade de ampliação de imóvel próprio ou comercial',
                image: 'globe'
            },
            {
                text: 'Ganhos reais no longo prazo para quem investe em construção de imóveis para revenda',
                image: 'percentage'
            },
            {
                text: 'Oportunidade extra para oferecer crédito para clientes com necessidades mais desafiadoras',
                image: 'extra'
            },
        ],
        advantages: [
            {
                text: 'Crédito disponível para cliente PF e PJ',
                image: 'board'
            },
            {
                text: 'Empréstimo de até R$ 500 mil',
                image: 'hope'
            },
            {
                text: 'Taxas a partir de 1,08 + IPCA a.m.',
                image: 'working'
            },
            {
                text: 'Composição de renda familiar variada com mais de uma pessoa',
                image: 'calendar'
            },
            {
                text: 'Liberação do recurso conforme andamento da obra',
                image: 'hands'
            },
        ],
        disclaimer: {
            text: '“Ao conhecer a Crediblue e verificar os produtos oferecidos, me deparei com uma novidade que poucas instituições financeiras trabalham: o crédito para a construção de imóveis ou para término de uma construção. Um processo que é feito de forma inovadora e que oferece uma facilidade e comodidade para ser aplicado de várias formas e por várias pessoas ou empresas. Observei que são inúmeras as oportunidades de oferecer para o público de modo geral esse produto”',
            author: '-Amarante',
            image: 'business'
        }
    },
]
