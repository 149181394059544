import {
  Directive,
  ElementRef,
  HostListener,
  OnInit,
  forwardRef
} from '@angular/core';

import * as BrV from 'br-validations';
import * as StringMask from 'string-mask';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR
} from '@angular/forms';

export function ValidateCnpj() {
  return (c: FormControl) => {
    if (c.value && c.value.toString() !== '') {
      const err = {
        cnpjPattern: true
      };
      return c.value && !BrV.cnpj.validate(c.value) ? err : null;
    } else {
      return null;
    }
  };
}

@Directive({
  selector: '[cnpjMask]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CnpjDirective),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CnpjDirective),
      multi: true
    }
  ]
})
export class CnpjDirective implements OnInit, ControlValueAccessor {
  /** Pattern created by StringMask library*/
  private cnpjPattern = new StringMask('00.000.000/0000-00');

  /** Placeholders for the callbacks which are later provides by the Control Value Accessor*/
  public onChangeCallback = (_: any) => {
    /*Vazio*/
  };
  @HostListener('blur', ['$event'])
  public onTouchCallback = () => {
    /*Vazio*/
  };
  validateFn: any = () => {
    /*Vazio*/
  };

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    const cleanValue: string = this._cleanValue(
      this.elementRef.nativeElement.value
    );
    this._applyValueChanges(cleanValue);
  }

  @HostListener('input')
  onKeydow(): void {
    const cleanValue: string = this._cleanValue(
      this.elementRef.nativeElement.value
    );
    this._applyValueChanges(cleanValue);
  }

  public writeValue(modelValue): void {
    if (modelValue === null || modelValue === undefined) {
      this.elementRef.nativeElement.value = '';
      return;
    }

    const cleanValue: string = this._cleanValue(modelValue);
    this._applyValueChanges(cleanValue);
  }

  public registerOnChange(fn: any): void {
    this.onChangeCallback = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouchCallback = fn;
  }

  public _applyValueChanges(cleanValue): void {
    this.elementRef.nativeElement.value = (
      this.cnpjPattern.apply(cleanValue) || ''
    )
      .trim()
      .replace(/[^0-9]$/, '');
    this.onChangeCallback(cleanValue);
  }

  public _cleanValue(viewValue: string): string {
    if (viewValue) {
      return viewValue.replace(/[^\d]/g, '').slice(0, 14);
    } else {
      return '';
    }
  }

  validate(c: FormControl) {
    if (c.value) {
      this.validateFn = ValidateCnpj();
    }
    return this.validateFn(c);
  }
}
