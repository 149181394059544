<section class="credi-container m-top-96">
  <ng-container *ngIf="post$ | async as post">
    <div class="blog-post-banner" [style.background-image]='"linear-gradient(0, rgba(0, 0, 0, 0.8) 0%, rgba(0, 24, 113, 0.3) 20%, rgba(0, 24, 113, 0.00) 40%), linear-gradient(180deg, rgba(0, 0, 0, 0.9) 0%, rgba(0, 24, 113, 0.4) 30%, rgba(0, 24, 113, 0.00) 50%), url(" + post.cover.uri + ")"'>
      <h5>{{ post?.category }}</h5>
      <h2>{{ post?.title }}</h2>
      <h5>por: {{ post?.author }} | {{ post?.publishedAt }}</h5>
    </div>
    <div class="blog-post">
      <h2>{{ post?.resume }}</h2>
      <div class="blog-post-content" [innerHTML]="post?.content"></div>
    </div>
    <hr class="w-100">
    <div class="share">
      <div class="share-options">
        <p>Gostou? <span>Compartilhe</span></p>
        <div>
          <a class="rounded blue-700" target="_blank" href="https://www.youtube.com/@crediblue5861">
            <app-cred-icon name="youtube" size="n5">
            </app-cred-icon>
          </a>
          <a class="rounded blue-700" target="_blank" href="https://www.instagram.com/crediblue/">
            <app-cred-icon name="instagram" size="n5">
            </app-cred-icon>
          </a>
          <a class="rounded blue-700" target="_blank" href="https://www.linkedin.com/company/crediblue/">
            <app-cred-icon name="linkedin" size="n5">
            </app-cred-icon>
          </a>
        </div>
      </div>
      <app-newsletter-form-small></app-newsletter-form-small>
    </div>

    <div class="recommendations" *ngIf="recommendations$ | async as recommendations">
      <h2 *ngIf="recommendations?.length > 0">
        Veja também
      </h2>
      <div>
        <app-post-card *ngFor="let recommendation of recommendations" [post]="recommendation"></app-post-card>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="!(post$ | async)">
    <div class="credi-center f-column f-gap-32 p-vertical-24">
      <h6>
        O post não foi encontrado!
      </h6>
      <app-newsletter-form></app-newsletter-form>
    </div>
  </ng-container>
</section>