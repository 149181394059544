import { Component, Inject, OnInit, Injector } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Term } from 'src/app/models/term.model';
import { UtilComponent } from 'src/app/util/util.component';

@Component({
  selector: 'app-dialog-term',
  templateUrl: './dialog-term.component.html',
  styleUrls: ['./dialog-term.component.css']
})
export class DialogTermComponent extends UtilComponent implements OnInit {
  termDescription: string = "";
  termContent: Term;
  dataSnapshotForTermRendering: string;

  constructor(
    injector: Injector,
    public dialogRef: MatDialogRef<DialogTermComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      termContent: Term,
      termDescription: string,
      dataSnapshotForTermRendering: string
    },
  ) {
    super(injector);
    this.termContent = this.data.termContent;
    this.termDescription = this.data.termDescription;
    this.dataSnapshotForTermRendering = this.data.dataSnapshotForTermRendering
  }

  async ngOnInit() {
    this.loadCurrentTerm();
  }

  public closeDialog(): void {
    this.dialogRef.close();
  }



  public loadCurrentTerm() {
    try {
      this.termContent.markup = this.buildTermTemplate(this.termContent, this.dataSnapshotForTermRendering);
    } catch (err: any) {
    }
  }

}
