import {Component} from '@angular/core';
import {SimpleModalComponent} from 'ngx-simple-modal';

export interface ModalModel {
  partnerName: string;
}

@Component({
  selector: 'confirm',
  templateUrl: './dialog-modal.component.html',
  styleUrls: ['./dialog-modal.component.scss']
})
export class DialogModalComponent extends SimpleModalComponent<ModalModel, boolean> implements ModalModel {
  partnerName: string | any;

  constructor() {
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }
}
