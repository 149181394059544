import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./views/home/home.component";
import {CreditTypeComponent} from "./views/credit-type/credit-type.component";
import {ForPartnersComponent} from "./views/for-partners/for-partners.component";
import {CareersComponent} from "./views/careers/careers.component";
import {HelpTabComponent} from './views/help/help-tab.component';
import {BePartnerFormComponent} from "./views/be-partner-form/be-partner-form.component";
import {EmbeddedSimulatorComponent} from "./components/simulator/embedded-simulator/embedded-simulator.component";
import {BlogListComponent} from "./views/blog/blog-list/blog-list.component";
import {BlogPostComponent} from "./views/blog/blog-post/blog-post.component";
import { PageQualificationOfferComponent } from './views/qualification-offer/page-qualification-offer/page-qualification-offer.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'home',
    component: HomeComponent
  },
  {
    path: 'solutions',
    children: [
      {
        path: 'credit-type',
        component: CreditTypeComponent
      },
      {
        path: 'simulator',
        component: EmbeddedSimulatorComponent
      },
      {
        path: 'for-partners',
        component: ForPartnersComponent
      },
    ]
  },
  {
    path: 'careers',
    component: CareersComponent
  },
  {
    path: 'help',
    component: HelpTabComponent
  },
  {
    path: 'be-partner',
    component: BePartnerFormComponent
  },
  {
    path: 'blog',
    children: [
      {
        path: 'post/:slug', component: BlogPostComponent
      },
      {
        path: '', component: BlogListComponent
      }
    ]
  },
  {
    path: 'qualificacao-bndes/:offerId/parceiro/:partnerLink',
    component: PageQualificationOfferComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: "enabled",
      useHash: false
    }
  )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
