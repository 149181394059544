import {Component, Injector, OnInit} from '@angular/core';
import {UtilComponent} from "../../../util/util.component";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ActiveContact} from "../../../models/active-contact.model";
import { BehaviorSubject } from 'rxjs';
import { NewsletterService } from 'src/app/services/newsletter.service';
import { NotificationService } from 'src/app/services/notification.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-newsletter-form-small',
  templateUrl: './newsletter-form-small.component.html',
  styleUrls: ['./newsletter-form-small.component.scss']
})
export class NewsletterFormSmallComponent implements OnInit {

  @BlockUI('blockUiContainer')
  private blockUI: NgBlockUI;

  public form$: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(null);

  constructor(
    private fb: FormBuilder,
    private newsletterService: NewsletterService,
    private notificationService: NotificationService,
  ) {
  }

  public ngOnInit(): void {
    this.createForm();
  }

  public createForm(): void {
    this.form$.next(
      this.fb.group({
        email: ['', Validators.required]
      })
    );
  }

  public get emailControl(): FormControl {
    return this.form.controls['email'] as FormControl;
  }

  private get formValid(): boolean {
    return this.emailControl.errors == null;
  }

  private get form(): FormGroup {
    return this.form$.value;
  }

  public onSubmit(): void {
    if(this.formValid) {
      this.blockUI.start();
      this.newsletterService.sendNewsletterContact(this.form.value as ActiveContact).subscribe({
        next: () => {
          this.notificationService.showSuccess("Cadastro realizado", "Newsletter");
          this.blockUI.stop();
        },
        error: (error) => {
          this.notificationService.showError(error.error.message, "Erro");
          this.blockUI.stop();
        }
      });
    }
  }
}
