import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient} from "@angular/common/http";
import { Observable } from "rxjs";
import {IConfigurationTenant} from "../models/configuration-tenant.model";
import {Term} from "../models/term.model";
// @ts-ignore
import ejs from "../../assets/js/ejs.min";
import { LandingPageDataResponse } from '../models/landing-page-data-response.model';
import { BasicPartnerOpportunityDto } from '../models/basic-partner-opportunity-dto';
import { Partner } from '../models/partner.model';

@Injectable({
  providedIn: 'root'
})
export class OportunityService {
  defaultPartnerTenant: string;

  constructor(private http: HttpClient) {
    this.defaultPartnerTenant = environment.defaultPartnerTenant;
  }

  public saveBasicPartnerOpportunity(
      basicPartnerOpportunity: BasicPartnerOpportunityDto): Observable<Partner> {
    return this.http.post<Partner>(environment.apiUrl + '/partners/basic-opportunity', basicPartnerOpportunity);
  }

  getPartnerInfo(): Observable<any> {
    return this.findLandingPageDataByLink(this.defaultPartnerTenant);
  }

  findLandingPageDataByLink(link: string): Observable<LandingPageDataResponse> {
    return this.http.get<LandingPageDataResponse>(`${environment.apiUrl}/partners/link/${link}`);
  }

  getCurrentTenant(): Observable<IConfigurationTenant> {
    return this.http.get<IConfigurationTenant>(environment.apiUrl + '/configuration-tenants');
  }

  public buildTermTemplate(term: Term, dataSnapshotForTermRendering: string): any {
    let ejsTemplate = "";

    term.topics.forEach(topic => {
      ejsTemplate += `<h2>${topic.name}</h2>`;
      ejsTemplate += topic.content;
    });

    return ejs.render(ejsTemplate.replace(/&lt;/g, '<').replace(/&gt;/g, '>'), JSON.parse(dataSnapshotForTermRendering));
  }
}
