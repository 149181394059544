import {Injectable, Injector} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {SimpleModalService} from 'ngx-simple-modal';
import {NotificationService} from '../services/notification.service';
import {OportunityService} from '../services/oportunity.service';
import {DialogModalComponent} from '../components/modal/dialog-modal/dialog-modal.component';
import {NewsletterService} from '../services/newsletter.service';
import {ActivatedRoute, Router} from "@angular/router";
import {PersonType} from "../data/personType";
import {ContactEmail, Persona, PersonaPhone} from "../data/persona.model";
import {PaginatorModel} from "../models/paginator.model";
import { TaxIdFormatPipe } from '../models/taxId-format.pipe';
import { IConfigurationTenant } from '../models/configuration-tenant.model';
import { LandingPageDataResponse } from '../models/landing-page-data-response.model';
import { Term } from '../models/term.model';
// @ts-ignore
import ejs from './../../assets/js/ejs.min.js';
import {Meta, Title} from "@angular/platform-browser";
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable()
export abstract class UtilComponent {

  public fb: FormBuilder;
  public oportunityService: OportunityService;
  public notifyService: NotificationService;
  public simpleModalService: SimpleModalService;
  public newsletterService: NewsletterService;
  public routerService: Router;
  public metaService: Meta;
  public titleService: Title;
  public route: ActivatedRoute

  @BlockUI('blockUiContainer')
  blockUI: NgBlockUI;

  constructor(
    private injector: Injector
  ) {
    this.fb = this.injector.get(FormBuilder);
    this.oportunityService = this.injector.get(OportunityService);
    this.notifyService = this.injector.get(NotificationService);
    this.simpleModalService = this.injector.get(SimpleModalService);
    this.newsletterService = this.injector.get(NewsletterService);
    this.routerService = this.injector.get(Router);
    this.metaService = this.injector.get(Meta);
    this.titleService = this.injector.get(Title);
    this.route = this.injector.get(ActivatedRoute);
  }

  showPrivaciesPoliciesDocument() {
    this.simpleModalService.addModal(DialogModalComponent, {
      partnerName: 'Blue Tecnologia',
    });
  }

  getPersonaName(persona: Persona | any): string {
    let personaName = '';

    if (persona) {
      switch (persona.personaType) {
        case PersonType.PF:
          personaName = persona.name;
          break;
        case PersonType.PJ:
          personaName =
            persona.companyData?.fantasyName ??
            persona.companyData?.corporateName ??
            persona?.name;
          break;
        default:
          personaName =
            persona.name ??
            persona.companyData?.fantasyName ??
            persona.companyData?.corporateName;
          break;
      }
    }
    return personaName;
  }

  findMainEmail(contacts: Array<ContactEmail> = []): string | undefined | null {
    return contacts && contacts?.length > 0
      ? contacts.find((contactEmail: ContactEmail) => contactEmail?.principal)
        ?.email
      : null;
  }

  findMainPhone(phones: Array<PersonaPhone> = []): string | undefined | null {
    return phones && phones?.length > 0
      ? phones.find((personaPhone: PersonaPhone) => personaPhone?.principal)
        ?.phone?.number
      : null;
  }

  public formatDate(dateString: any) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    return date.toLocaleDateString('pt-BR', options);
  }

  public anyStringValuesPresent(...values: string[]): boolean {
    return values?.some(value => this.isStringValuePresent(value));
  }

  public isStringValuePresent(value: string): boolean {
    return value && value !== '';
  }

  paginator(
    items: any,
    page: number,
    size: number,
    totalPages: number,
    totalItens: number,
    last?: boolean
  ): PaginatorModel {
    const page_tmp = page || 1;
    const size_tmp = size || 10;

    return new PaginatorModel(
      page_tmp,
      size_tmp,
      page_tmp - 1 ? page_tmp - 1 : null,
      totalPages > page_tmp ? page_tmp + 1 : null,
      totalItens,
      totalPages,
      items,
      last
    );
  }

  public buildDataSnapshotForTermRendering(landingPageDataResponse: LandingPageDataResponse, tenant: IConfigurationTenant): string {
    const taxIdFormatter = new TaxIdFormatPipe();
    return JSON.stringify({
      partner: {
        name: this.getPersonaName(landingPageDataResponse?.persona),
        email: this.findMainEmail(landingPageDataResponse?.persona?.contacts),
        phone: this.findMainPhone(landingPageDataResponse?.persona?.phones),
        taxId: taxIdFormatter.transform(landingPageDataResponse?.persona?.taxId)
      },
      tenant: {
        name: this.getPersonaName(tenant?.persona),
        email: this.findMainEmail(tenant?.persona?.contacts),
        phone: this.findMainPhone(tenant?.persona?.phones),
        taxId: taxIdFormatter.transform(tenant?.persona?.taxId)
      }
    });
  }

  public buildTermTemplate(term: Term, dataSnapshotForTermRendering: string): any {
    let ejsTemplate = "";

    term.topics.forEach(topic => {
      ejsTemplate += `<h2>${topic.name}</h2>`;
      ejsTemplate += topic.content;
    });

    return ejs.render(ejsTemplate.replace(/&lt;/g, '<').replace(/&gt;/g, '>'), JSON.parse(dataSnapshotForTermRendering));
  }

  public customizeMetatags(title: string, description: string) {
    this.titleService.setTitle(title);
    this.metaService.updateTag({
      name: 'title',
      content: title
    });
    this.metaService.updateTag({
      name: 'description',
      content: description
    });
  }
}
