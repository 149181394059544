<div class="overflow-x-hidden h-100">
  <div class="div-width h-70 pos-relative">
    <div class="w-100 flex-item f-gap-24 pos-absolute align-cards" [@scrollLeft]="'position'.concat(position)">

      <div class="w-20" *ngFor="let content of contents">
        <app-home-card [content]="content"></app-home-card>
      </div>

    </div>
  </div>
  <div class="flex-item h-10 buttons-width">
    <button *ngIf="position === '1'">
    </button>
    <button (click)="onClickChange(-1)" *ngIf="position !== '1'">
      <app-cred-icon name="arrow-left" size="n8"></app-cred-icon>
    </button>
    <div class="flex-item">
      <ng-container *ngFor="let number of [1, 2, 3, 4, 5, 6]">
        <button [class]="isActive(number)" (click)="onClickDotChange(number)"></button>
      </ng-container>
    </div>
    <button (click)="onClickChange(1)" *ngIf="position !== '6'">
      <app-cred-icon name="arrow-right" size="n8"></app-cred-icon>
    </button>
    <button *ngIf="position === '6'">
    </button>
  </div>
</div>
