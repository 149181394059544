import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { CrediblueMobileMenuService } from './crediblue-mobile-menu.service';
import { Subscription, distinctUntilChanged, fromEvent, map } from 'rxjs';

@Component({
  selector: 'crediblue-mobile-menu',
  templateUrl: './crediblue-mobile-menu.component.html',
  styleUrls: ['./crediblue-mobile-menu.component.scss'],
  host: {
    class: 'crediblue-mobile-menu',
  },
})
export class CrediblueMobileMenuComponent implements OnDestroy, AfterViewInit {
  @Input() public hideIn: number;
  public menuActive: boolean = false;
  private crediblueMobileMenu: HTMLElement;
  private subs: Array<Subscription> = [];
  constructor(
    ref: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private crediblueMobileMenuService: CrediblueMobileMenuService
  ) {
    this.crediblueMobileMenu = ref.nativeElement;
    this.subs.push(
      this.crediblueMobileMenuService.onToggle.subscribe(() => this.onChange())
    );
  }

  ngAfterViewInit(): void {
    if (this.hideIn) {
      fromEvent(window, 'resize')
        .pipe(
          map(() => window.innerWidth),
          distinctUntilChanged()
        )
        .subscribe((innerWidth) => {
          if (innerWidth >= this.hideIn) {
            this.closeMobileMenuState();
          }
        });
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach((sub) => sub?.unsubscribe());
  }

  @HostListener('mousewheel', ['$event'])
  public onWheel(e: WheelEvent) {
    e.stopPropagation();
  }

  public onChange() {
    this.activeMenu(this.crediblueMobileMenu);
  }
  public activeMenu(el: HTMLElement) {
    const active = el.getAttribute('active');
    if (active) {
      this.renderer.removeAttribute(el, 'active');
    } else {
      this.renderer.setAttribute(el, 'active', String(true));
      this.menuActive = true;
    }
  }

  public closeMobileMenuState() {
    this.renderer.removeAttribute(this.crediblueMobileMenu, 'active');
    this.menuActive = false;
  }
}
