import {Component, Input} from '@angular/core';
import {HomeCards} from 'src/app/models/contents.model';

@Component({
  selector: 'app-home-card',
  templateUrl: './home-card.component.html',
  styleUrls: ['./home-card.component.scss']
})
export class HomeCardComponent {

  @Input()
  content!: HomeCards;

}
